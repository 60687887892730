import ApiService from '../core/apiService';
import type { Address } from '../core/types/address';

const getUserAddresses = () => {
  const userId = ApiService.getUserId();
  if (!userId) {
    throw new Error('common:Errors.Unauthorized');
  }
  return ApiService.action('get_user_addresses', {
    method: 'get',
    urlParams: {
      userId,
    },
  });
};

const saveUserAddress = (address: Address) => {
  const userId = ApiService.getUserId();
  if (!userId) {
    throw new Error('common:Errors.Unauthorized');
  }
  return ApiService.action('add_user_address', {
    method: 'post',
    urlParams: {
      userId,
    },
    data: address,
  });
};

export function createAddressService() {
  const service = {
    init: ApiService.init,
    getUserAddresses,
    saveUserAddress,
  };
  return service;
}

const AddressService = createAddressService();
export default AddressService;
