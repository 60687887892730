import i18next from 'i18next';
import React, { useState } from 'react';
import { Button, EButtonColor, Checkbox } from 'web_opxp_components';
import { ICookieConsent } from './types';

export interface ICookieSettingsProps {
  firebase: boolean;
  optimize: boolean;
  privacyUrl: string;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (consent: ICookieConsent) => void;
}

const CookieSettings: React.FC<ICookieSettingsProps> = ({
  firebase,
  optimize,
  privacyUrl,
  visible,
  onCancel,
  onSubmit,
}) => {
  const [firebaseConsent, setFirebase] = useState(firebase);
  const [optimizeConsent, setOptimize] = useState(optimize);
  if (!visible) {
    return null;
  }
  const onSaveConsent = () => {
    onSubmit({
      firebase: firebaseConsent,
      optimize: optimizeConsent,
    });
  };
  const onAcceptAll = () => {
    onSubmit({
      firebase: true,
      optimize: true,
    });
  };
  const privacyPolicyLink = `<a href="${privacyUrl}" rel="noopener noreferrer" target="_blank">${i18next.t(
    'common.common:Cookies.LinkLabel'
  )}</a>`;
  const cookieDescription = i18next.t('common.common:Cookies.Description');
  const firebaseCheckboxLabel = i18next.t('common.common:Cookes.Option', { privacyPolicyLink });
  const firebaseConsentHandler = () => {
    const newConsent = !firebaseConsent;
    setFirebase(newConsent);
    setOptimize(newConsent);
  };
  const optimizeCheckboxLabel = i18next.t('common.common:Cookies.OptimizeHtml');
  const optimizeConsentHandler = () => setOptimize(!optimizeConsent);
  return (
    <>
      <div className="cookie-consent-modal-overlay" />
      <div className="cookie-consent-modal">
        <div className="modal-header">
          <h2>{i18next.t('common.common:Cookies.Settings')}</h2>
          <span
            className="close-button"
            role="button"
            onClick={() => onCancel()}
            title={i18next.t('common.common:Cookies.Close')}>
            x
          </span>
        </div>
        <p dangerouslySetInnerHTML={{ __html: cookieDescription }} />
        <div className="modal-form">
          <Checkbox id="firebase" checked={firebaseConsent} onChange={firebaseConsentHandler}>
            <span dangerouslySetInnerHTML={{ __html: firebaseCheckboxLabel }} />
          </Checkbox>
          <Checkbox
            id="optimize"
            disabled={!firebaseConsent}
            checked={optimizeConsent}
            onChange={optimizeConsentHandler}>
            <span dangerouslySetInnerHTML={{ __html: optimizeCheckboxLabel }} />
          </Checkbox>
        </div>
        <div className="buttons-container">
          <Button
            color={EButtonColor.white}
            label={i18next.t('common.common:Cookies.CTA.Save')}
            onClick={onSaveConsent}
          />
          <Button
            color={EButtonColor.blue}
            label={i18next.t('common.common:Cookies.CTA.ActivateAll')}
            onClick={onAcceptAll}
          />
        </div>
      </div>
    </>
  );
};

export default CookieSettings;
