import type { Price } from './price';
import type { Coupon } from './coupon';

export enum ECartItemType {
  ONE_TIME = '1',
  BUNDLE = '2',
  SUBSCRIPTION = '3',
  GROUP_ONE_TIME = '4',
  GROUP_SUBSCRIPTION = '5',
}

export interface CartItem {
  id: string;
  name: string;
  type: ECartItemType;
  requiredQuantity: number;
  totalOriginalPrice: Price;
  totalDiscountedPriceWithoutApplyingCoupon: Price;
  totalDiscountedPrice: Price;
  price: Price;
  discount: Price;
  discountPercentage: number;
  discountedPrice: Price;
  coupon?: Coupon;
  customData: unknown;
}

export interface Cart {
  // global discount for the whole cart
  coupon?: Coupon;
  // products/items in cart
  items: {
    [id: string]: CartItem;
  };
  // total price
  originalTotal: Price;
  total: Price;
  totalWithoutApplyingCoupons: Price;
}
