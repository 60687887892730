import axios, { AxiosInstance, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import map from 'lodash/map';
import { getApiService, HttpError, SharedErrors } from 'web_opxp_components';
import { BaseError } from 'web_opxp_components/dist/errors';
import ENV from '../env';
import { removeSessionToken } from '../helpers/parameters';
import { getUserId } from '../helpers/sessionStorage';

interface IError {
  message: string;
  field?: string;
  error_code?: string;
  invalidCoupon?: boolean;
}

interface IBackendErrorMessage {
  message: string;
  error_code: string;
  field?: string;
}

interface IInvalidAddressError {
  [field: string]: IBackendErrorMessage;
}

export interface IUserApiService {
  action: (actions: string, config: any) => Promise<HttpError | AxiosResponse<any, any>>;
  init: (session?: string) => void;
  getUserId: () => number | undefined;
}

function getAxiosInstance() {
  return axios.create();
}

const axiosInstance = getAxiosInstance();

export function handleError(error: SharedErrors.BaseError | Error) {
  let errors: IError[] = [];
  if (error instanceof HttpError) {
    const status = error.status;
    switch (status) {
      case 401:
        // when authorization fails - remove saved session token
        removeSessionToken();
        errors.push({ message: 'common:Errors.Unauthorized' });
        break;
      case 400: {
        const data: IBackendErrorMessage[] | IInvalidAddressError = error.data.errors || [];
        errors = map(data, (error, field) => {
          const params: Partial<IError> = {};
          let message;
          if (typeof field === 'string') {
            // address form different error format workaround
            message = [`Errors.Invalid.${field}`, 'common:FormValidation.Required'];
            params.field = field;
          } else {
            const backendError = error as IBackendErrorMessage;
            message = backendError.message || 'Errors.Unknown';
            if (backendError.error_code) {
              // coupon error_code starts with c and followed by some numbers, for example c72
              // as for now we don't differentiate different coupon related errors
              const invalidCoupon = backendError.error_code.match(/c\d+/);
              const code = invalidCoupon ? 'COUPON_INVALID' : backendError.error_code;
              message = [`Errors.Invalid.${code}`, `Errors.${code}`, message];
              params.error_code = backendError.error_code;
              if (invalidCoupon) {
                params.invalidCoupon = !!invalidCoupon;
              }
            }
            if (backendError.field) {
              message = [`Errors.Invalid.${backendError.field}`, 'common:FormValidation.Required', message];
              params.field = backendError.field;
            }
          }
          return Object.assign(params, { message });
        });
        break;
      }
      case 412: {
        // this is not an error
        return Promise.resolve(error);
      }
      default:
        // fallback for any other unexpected error
        errors.push({ message: 'common:Errors.HttpUnknown' });
        break;
    }
  } else {
    errors.push({ message: error.message });
  }
  return Promise.reject(errors);
}

export function addErrorInterceptor(override?: AxiosInstance) {
  const instance = override ? override : axiosInstance;
  instance.interceptors.response.use(undefined, handleError);
}

export function addRetry(override?: AxiosInstance) {
  const instance = override ? override : axiosInstance;
  axiosRetry(instance, { retries: 5, retryDelay: axiosRetry.exponentialDelay });
}

export function createApiService() {
  const apiConfigUrl = `${ENV.ApiUrl}/config?appVersion=${ENV.ApiAppVersion}`;
  const api = getApiService(apiConfigUrl, axiosInstance);
  let userId: number | undefined;
  const init = (session?: string) => {
    userId = Number(getUserId());
    if (session) {
      api.setToken(session);
    }
  };
  const action = async <T>(action: string, config: any) => {
    try {
      const response = await api.action<T>(action, config);
      return response;
    } catch (error) {
      const errors = await handleError(error as Error | BaseError);
      return errors;
    }
  };
  const service: IUserApiService = {
    action,
    init,
    getUserId: () => userId,
  };
  return service;
}

const ApiService = createApiService();
export default ApiService;
