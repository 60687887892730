import { combineReducers } from 'redux';
import { FirebaseReducer } from '../../../firebase';
import { GdprReducer } from '../../../gdpr';
import { LanguageReducer } from '../../../language';
import { OrdersReducer } from '../../../orders';
import { TicketReducer } from '../../../ticket';
import addressReducer, { initState as addressState } from './addressReducer';
import appReducer, { initState as appState } from './appReducer';
import cartReducer, { initState as cartState } from './cartReducer';
import checkoutReducer, { initState as checkoutState } from './checkoutReducer';
import errorsReducer, { initState as errorsState } from './errorsReducer';
import notificationReducer, { initState as notificationState } from './notificationReducer';
import packagesReducer, { initState as packagesState } from './packagesReducer';
import paymentReducer, { initState as paymentState } from './paymentReducer';
import subscriptionReducer, { initState as subscriptionState } from './subscriptionReducer';

export const initialState = {
  app: appState,
  cart: cartState,
  payment: paymentState,
  address: addressState,
  packages: packagesState,
  errors: errorsState,
  checkout: checkoutState,
  subscription: subscriptionState,
  notification: notificationState,
  [OrdersReducer.statePropName]: OrdersReducer.initState,
  [GdprReducer.statePropName]: GdprReducer.initialState,
  [FirebaseReducer.statePropName]: FirebaseReducer.initialState,
  [LanguageReducer.statePropName]: LanguageReducer.initialState,
  [TicketReducer.statePropName]: TicketReducer.initState,
};

const rootReducer = combineReducers({
  cart: cartReducer,
  payment: paymentReducer,
  app: appReducer,
  address: addressReducer,
  packages: packagesReducer,
  errors: errorsReducer,
  checkout: checkoutReducer,
  subscription: subscriptionReducer,
  notification: notificationReducer,
  [OrdersReducer.statePropName]: OrdersReducer.default,
  [GdprReducer.statePropName]: GdprReducer.default,
  [FirebaseReducer.statePropName]: FirebaseReducer.default,
  [LanguageReducer.statePropName]: LanguageReducer.default,
  [TicketReducer.statePropName]: TicketReducer.default,
});

export default rootReducer;
