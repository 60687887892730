import i18next from 'i18next';
import { put, call, takeEvery, all } from 'redux-saga/effects';
import { addNotificationAction } from '../core/redux/actions';
import { Notification, NotificationTypes } from '../core/types/notification';
import * as ActionTypes from './actionTypes';
import * as Actions from './actions';
import OrdersService from './service';
import { OrdersRequestResponse } from './types';

export function* handleInitOrders({ token }: ActionTypes.IInitOrdersAction) {
  try {
    // initialize app services with auth token
    OrdersService.init(token);
  } catch (error) {
    const message = i18next.t(error.message);
    const notification = new Notification(NotificationTypes.error, message, false);
    yield put(addNotificationAction(notification));
  }
}

export function* handleGetOrders() {
  try {
    const result: OrdersRequestResponse = yield call(OrdersService.getOrders);
    yield put(Actions.getOrdersSuccessAction(result));
  } catch (errors) {
    yield all(
      errors.map((error: Error) => {
        const message = i18next.t(error.message);
        const notification = new Notification(NotificationTypes.error, message, false);
        return put(addNotificationAction(notification));
      })
    );
    yield put(Actions.getOrdersFailedAction(errors));
  }
}

export default function* watchOrders(): Generator<any, any, any> {
  yield all([
    takeEvery(ActionTypes.ORDERS_INIT, handleInitOrders),
    takeEvery(ActionTypes.GET_ORDERS_REQUEST, handleGetOrders),
  ]);
}
