import { IUser } from '../../types/app';

export const APP_START = 'APP_START';
export const appStartAction = () => {
  return {
    type: APP_START,
  };
};

export const APP_STARTED = 'APP_STARTED';
export const appStartedAction = () => {
  return {
    type: APP_STARTED,
  };
};

export const USER_LOAD = 'USER_LOAD';
export const userLoadAction = (user: IUser | null) => {
  return {
    type: USER_LOAD,
    payload: { user },
  };
};

export const APP_LINKS_UPDATE = 'APP_LINKS_UPDATE';
export const updateLinksAction = (backUrl: string, completeUrl: string) => {
  return {
    type: APP_LINKS_UPDATE,
    payload: {
      backUrl,
      completeUrl,
    },
  };
};

export const APP_START_FAILED = 'APP_START_FAILED';
export const appStartFailedAction = (error: string) => {
  return {
    type: APP_START_FAILED,
    payload: {
      error,
    },
  };
};

export const APP_OPEN_TOU = 'APP_OPEN_TOU';
export const appOpenTouAction = () => {
  return {
    type: APP_OPEN_TOU,
  };
};

export const APP_CLOSE_TOU = 'APP_CLOSE_TOU';
export const appCloseTouAction = () => {
  return {
    type: APP_CLOSE_TOU,
  };
};

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const appLogoutAction = () => {
  return {
    type: AUTH_LOGOUT,
  };
};
