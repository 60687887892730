import { all, call, put, takeEvery } from 'redux-saga/effects';

import { FirebaseActions } from '../firebase';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import * as Service from './service';
import { EBannerMode, ICookieConsent } from './types';

// initialize gdpr feature
export function* initializeGdpr() {
  yield call(detectBannerMode);
  const sessionId: string | undefined = yield call(Service.getSessionId);
  yield put(FirebaseActions.firebaseInitAction(sessionId));
  const consent: ICookieConsent | undefined = yield call(Service.getConsent);
  if (consent) {
    yield put(Actions.updateConsentAction(consent));
  }
}

export function* useConsentSaga({ consent }: ActionTypes.IUpdateConsentAction) {
  // start/init features depending on users consent
  const { firebase, optimize } = consent;
  if (firebase) {
    yield put(FirebaseActions.firebaseStartAction(firebase, optimize));
  } else {
    yield put(FirebaseActions.disableCookiesAction());
  }
}

export function* saveUserConsentSaga({ consent }: ActionTypes.ISaveUserConsentAction) {
  yield call(Service.saveConsent, consent);
  yield put(Actions.updateConsentAction(consent));
}

export function* detectBannerMode() {
  // detectUTMTracking must be run before this function
  const cks: string = yield call(Service.getCks);
  if (!cks) {
    // do nothing if parameter not set
    return;
  }
  const mode: EBannerMode = Number(cks);
  yield put(Actions.updateBannerModeAction(mode));
}

// base watcher for gdpr actions
export default function* watchGdpr() {
  yield all([
    takeEvery(ActionTypes.GDPR_INIT, initializeGdpr),
    takeEvery(ActionTypes.GDPR_UPDATE_CONSENT, useConsentSaga),
    takeEvery(ActionTypes.GDPR_SAVE_USER_CONSENT, saveUserConsentSaga),
  ]);
}
