import Env from '../env';
import { IBaseRemoteConfig } from './types';

export const RESTORE_SUBSCRIPTION_PRODUCTS_CONFIG = Env.RestoreSubscriptionRemoteConfig;

export const DEFAULT_RESTORE_SUBSCRIPTION_PRODUCTS = '100';

export function getDefaultRestoreSubscriptionProducts() {
  return DEFAULT_RESTORE_SUBSCRIPTION_PRODUCTS;
}

export function getDefaultRemoteConfig(): IBaseRemoteConfig {
  return {
    [RESTORE_SUBSCRIPTION_PRODUCTS_CONFIG]: getDefaultRestoreSubscriptionProducts(),
  };
}

export interface IRemoteConfig {
  restoreProducts: string;
}
