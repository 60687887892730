import { put, call, takeEvery, all, fork, take } from 'redux-saga/effects';

import type { Cart } from '../../types/cart';

import {
  ADD_PRODUCT_TO_CART_REQUEST,
  DELETE_COUPONS_FROM_CART_REQUEST,
  addProductToCartSuccessAction,
  addProductToCartFailAction,
  deleteCouponsFromCartSuccessAction,
  deleteCouponsFromCartFailAction,
  ADD_COUPON_TO_CART_REQUEST,
  addCouponToCartSuccessAction,
  addCouponToCartFailAction,
  getSubscriptionDetailsRequestAction,
  GET_SUBSCRIPTION_DETAILS_REQUEST,
  getSubscriptionDetailsSuccessAction,
  getSubscriptionDetailsFailAction,
  GET_SUBSCRIPTION_DETAILS_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_FAIL,
  updateSubscriptionDetailsAction,
  addProductToCartRequestAction,
  addProductToCartWrongCouponAction,
  getPaymentMethodsListAction,
} from '../actions';

import paymentService from '../../../services/paymentService';
import { clearParameter, UrlParams } from '../../../helpers/parameters';

import { isSubscription } from '../../../helpers/checkCartItemType';

export function* handleSubscriptionDetailsRequest(action) {
  try {
    const response = yield call([paymentService, 'getSubscriptionDetails'], action.payload.productId);
    yield put(getSubscriptionDetailsSuccessAction(response));
  } catch (error) {
    yield put(getSubscriptionDetailsFailAction(error));
  }
}

export function* requestSubscriptionDetails(productId) {
  yield put(getSubscriptionDetailsRequestAction(productId));
  const result = yield take([GET_SUBSCRIPTION_DETAILS_SUCCESS, GET_SUBSCRIPTION_DETAILS_FAIL]);
  if (result.type === GET_SUBSCRIPTION_DETAILS_SUCCESS) {
    yield put(updateSubscriptionDetailsAction(productId, result.payload.response.data.subscriptionType));
  }
}

export function* handleAddProductToCartRequest(action) {
  try {
    const response = yield call([paymentService, 'addProductToCart'], action.payload.productId, action.payload.coupon);
    const cart: Cart = response.data.cart;
    if (cart && cart.items) {
      for (const productId in cart.items) {
        const cartItem = cart.items[productId];

        if (isSubscription(cartItem.type)) {
          yield fork(requestSubscriptionDetails, productId);
        }
      }
    }
    localStorage.setItem(UrlParams.Product, action.payload.productId);
    yield put(addProductToCartSuccessAction(response));
    yield put(getPaymentMethodsListAction());
  } catch (errors) {
    if (errors && errors.length > 0) {
      // check if coupon was wrong
      const couponError = errors.findIndex((error) => error.invalidCoupon === true);
      if (couponError > -1) {
        yield put(addProductToCartWrongCouponAction());
        yield call(clearParameter, UrlParams.Coupon);
        // try to restart request without the coupon
        yield put(addProductToCartRequestAction(action.payload.productId));
      } else {
        yield put(addProductToCartFailAction(errors));
      }
    }
  }
}

export function* handleDeleteCouponsFromCartRequest() {
  try {
    const response = yield call([paymentService, 'deleteCartCoupons']);

    localStorage.removeItem(UrlParams.Coupon);
    yield put(deleteCouponsFromCartSuccessAction(response));
    yield put(getPaymentMethodsListAction());
  } catch (error) {
    yield put(deleteCouponsFromCartFailAction(error));
  }
}

export function* handleAddCouponToCartRequest(action) {
  try {
    const coupon = action.payload.coupon;
    const response = yield call([paymentService, 'addCartCoupon'], coupon);

    localStorage.setItem(UrlParams.Coupon, coupon);
    yield put(addCouponToCartSuccessAction(response));
    yield put(getPaymentMethodsListAction());
  } catch (error) {
    yield put(addCouponToCartFailAction(error));
  }
}

export default function* watchCart() {
  yield all([
    takeEvery(ADD_PRODUCT_TO_CART_REQUEST, handleAddProductToCartRequest),
    takeEvery(DELETE_COUPONS_FROM_CART_REQUEST, handleDeleteCouponsFromCartRequest),
    takeEvery(ADD_COUPON_TO_CART_REQUEST, handleAddCouponToCartRequest),
    takeEvery(GET_SUBSCRIPTION_DETAILS_REQUEST, handleSubscriptionDetailsRequest),
  ]);
}
