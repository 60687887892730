import { Action } from 'redux';
import { OrdersRequestError, OrdersRequestResponse } from './types';

export const ORDERS_INIT = 'ORDERS_INIT';
export type ORDERS_INIT_TYPE = typeof ORDERS_INIT;
export interface IInitOrdersAction extends Action<ORDERS_INIT_TYPE> {
  type: ORDERS_INIT_TYPE;
  token: string;
}

export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export type GET_ORDERS_REQUEST_TYPE = typeof GET_ORDERS_REQUEST;
export interface IGetOrdersRequestAction extends Action<GET_ORDERS_REQUEST_TYPE> {
  type: GET_ORDERS_REQUEST_TYPE;
}

export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export type GET_ORDERS_SUCCESS_TYPE = typeof GET_ORDERS_SUCCESS;
export interface IGetOrdersSuccessAction extends Action<GET_ORDERS_SUCCESS_TYPE> {
  type: GET_ORDERS_SUCCESS_TYPE;
  payload: {
    response: OrdersRequestResponse;
  };
}

export const GET_ORDERS_FAILED = 'GET_ORDERS_FAILED';
export type GET_ORDERS_FAILED_TYPE = typeof GET_ORDERS_FAILED;
export interface IGetOrdersFailedAction extends Action<GET_ORDERS_FAILED_TYPE> {
  type: GET_ORDERS_FAILED_TYPE;
  payload: {
    error: OrdersRequestError;
  };
}

export type IOrdersActions = IGetOrdersRequestAction | IGetOrdersSuccessAction | IGetOrdersFailedAction;
