import { IRoleData, IUser } from './types/app';

export enum Role {
  MODERATOR = 4,
  PREAUTHENTICATED = 9,
  WEBPREMIUM = 12,
}

export const findRole = (roles: IRoleData[], role: Role) => roles.find((item) => item.id === role);

export const hasRole = (user: IUser, role: Role) => {
  const roles = user.roles;

  if (!roles) {
    return false;
  }

  return !!findRole(roles, role);
};

export const hasPremiumRole = (user: IUser) => hasRole(user, Role.WEBPREMIUM);

export const prepareUserData = (user: IUser) => {
  return {
    mail: user.mail || '',
    userId: user.userId,
    picture: user.picture,
    name: user.name,
    isPremium: hasPremiumRole(user),
    language: user.language || 'de',
    username: user.username,
    isAuthorized: true,
  };
};
