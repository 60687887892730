import {
  Analytics,
  getAnalytics,
  isSupported,
  logEvent,
  setUserId as setAnalyticsUserId,
  setUserProperties,
} from 'firebase/analytics';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getString, RemoteConfig } from 'firebase/remote-config';
import { getDefaultRemoteConfig, RESTORE_SUBSCRIPTION_PRODUCTS_CONFIG } from './config';
import { setClientIdToPlugin } from './gtag';
import { IFirebaseConfigs, IEventActionPayload } from './types';
import { prepareFirebaseEventParams } from './utils';

function getFirebaseConfig() {
  let firebaseConfig: IFirebaseConfigs;
  if (process.env.NODE_ENV === 'test') {
    firebaseConfig = {
      apiKey: 'mock-api-key',
      authDomain: 'mock-auth-domain',
      databaseURL: 'mock-database-url',
      projectId: 'mock-project-id',
      storageBucket: 'mock-storage-bucket',
      messagingSenderId: 'mock-messaging-sender-id',
      appId: 'mock-app-id',
      measurementId: 'mock-measurement-id',
    };
  } else {
    firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };
  }
  return firebaseConfig;
}

async function detectIdb() {
  return new Promise<void>((yes, not) => {
    if (!window.indexedDB) {
      not('indexedDB not supported');
      return;
    }
    const db = window.indexedDB.open('test');
    if (!db) {
      not('indexedDB not supported');
      return;
    }
    db.onerror = function () {
      not('indexedDB not supported');
    };
    db.onsuccess = function () {
      db.result.close();
      window.indexedDB.deleteDatabase(db.result.name);
      yes();
    };
  });
}

async function initAnalytics(app: FirebaseApp) {
  const isAnalyticsSupported = await isSupported();
  if (!isAnalyticsSupported) {
    return;
  }
  return getAnalytics(app);
}

async function initConfig(app: FirebaseApp) {
  await detectIdb();
  return getRemoteConfig(app);
}

export function createFirebaseService() {
  const config = getFirebaseConfig();
  let firebaseApp: FirebaseApp | undefined = initializeApp(config);
  let analyticsApp: Analytics | undefined;
  let configApp: RemoteConfig | undefined;
  // propagate sessionId aka client_id to firebase analytics module
  const setSessionId = setClientIdToPlugin(config.measurementId!);

  const init = async (sessionId?: string) => {
    if (!firebaseApp) {
      firebaseApp = initializeApp(config);
    }
    if (sessionId) {
      setSessionId(sessionId);
    }
    analyticsApp = await initAnalytics(firebaseApp);
    configApp = await initConfig(firebaseApp);
  };

  const analyticsEvent = (analyticsEvent: IEventActionPayload) => {
    if (!analyticsApp) {
      return;
    }
    const analyticsEventData = prepareFirebaseEventParams(analyticsEvent.parameters);
    logEvent(analyticsApp, analyticsEvent.eventName, analyticsEventData);
  };

  const initializeConfig = () => {
    if (!configApp) {
      return;
    }
    configApp.settings = {
      minimumFetchIntervalMillis: 1000 * 60 * 5, // 5 min
      fetchTimeoutMillis: 1000 * 30, // 30 sec
    };
    configApp.defaultConfig = getDefaultRemoteConfig();
    return fetchAndActivate(configApp);
  };

  // set user id
  const setUserId = (userId: number) => {
    if (!analyticsApp) {
      return;
    }
    return setAnalyticsUserId(analyticsApp, `${userId}`);
  };

  // track if user gave the consent or not
  const setCookieConsentProperty = (consent: boolean) => {
    if (!analyticsApp) {
      return;
    }
    return setUserProperties(analyticsApp, { cookie_consent: consent });
  };

  const getRestoreSubscriptionProductsConfig = () => {
    if (!configApp) {
      return;
    }
    return getString(configApp, RESTORE_SUBSCRIPTION_PRODUCTS_CONFIG);
  };

  return {
    analyticsEvent,
    getRestoreSubscriptionProductsConfig,
    init,
    initializeConfig,
    setCookieConsentProperty,
    setUserId,
  };
}

export type IFirebaseService = ReturnType<typeof createFirebaseService>;

const firebaseInstance: IFirebaseService = createFirebaseService();
export default firebaseInstance;
