import * as Reducer from './reducer';
import { IOrder } from './types';

export interface IAppState {
  // this feature state
  [Reducer.statePropName]: Reducer.IOrdersState;
  // other features we don't care of
  [otherKeys: string]: any;
}

const getState = (state: IAppState) => state[Reducer.statePropName];

export const isLoaded = (state: IAppState) => getState(state).loaded;

export const getOrders = (state: IAppState) => getState(state).orders;

export const getLatestOrder = (state: IAppState): IOrder | null => {
  const orders = getOrders(state);

  if (!orders.length) {
    return null;
  }

  return orders.reduce((prevOrder, order) => (prevOrder.date > order.date ? prevOrder : order));
};
