import ApiService from '../core/apiService';

const completeCheckout = (stripePaymentMethodId: string, source: string, method?: string) => {
  return ApiService.action('checkout_complete', {
    method: 'post',
    data: {
      method: method === 'card' ? 'stripe_intents' : method,
      stripePaymentMethodId,
      source,
    },
  });
};

const confirmCheckout = (stripePaymentIntentId: string) => {
  return ApiService.action('checkout_complete', {
    method: 'post',
    data: {
      stripePaymentIntentId,
    },
  });
};

const addProductToCart = (productId: string, coupon?: string) => {
  return ApiService.action('checkout_cart_add_item', {
    method: 'post',
    data: {
      productId,
      coupon,
    },
  });
};

const getSubscriptionDetails = (productId: string) => {
  return ApiService.action('product_subscription_type', {
    method: 'get',
    urlParams: {
      id: productId,
    },
  });
};

const getPaymentSources = () => {
  const userId = ApiService.getUserId();
  if (!userId) {
    throw [new Error('common:Errors.Unauthorized')]; // eslint-disable-line
  }
  return ApiService.action('get_user_payment_sources', {
    method: 'get',
    urlParams: {
      userId,
    },
  });
};

const createPaymentSource = (stripeToken: { id: string }, type: string) => {
  const userId = ApiService.getUserId();
  if (!userId) {
    throw [new Error('common:Errors.Unauthorized')]; // eslint-disable-line
  }
  const id = (stripeToken && stripeToken.id) || '';
  let paymentSourceParams: any = {
    stripeToken: id,
    type: type || 'card',
  };
  if (type === 'card') {
    paymentSourceParams = {
      type: 'card_intents',
      stripePaymentMethodId: id,
    };
  }
  return ApiService.action('add_user_payment_source', {
    method: 'post',
    urlParams: {
      userId,
    },
    data: paymentSourceParams,
  });
};

const setDefaultPaymentSource = (token: string, type?: string) => {
  const userId = ApiService.getUserId();
  if (!userId) {
    throw [new Error('common:Errors.Unauthorized')]; // eslint-disable-line
  }
  let data: any = {
    token,
  };
  if (type === 'card') {
    data = {
      type: 'card_intents',
      stripePaymentMethodId: token,
    };
  }
  return ApiService.action('set_default_user_payment_source', {
    method: 'patch',
    urlParams: {
      userId,
    },
    data,
  });
};

const deletePaymentSource = (sourceId: string) => {
  const userId = ApiService.getUserId();
  if (!userId) {
    throw [new Error('common:Errors.Unauthorized')]; // eslint-disable-line
  }
  return ApiService.action('delete_user_payment_source', {
    method: 'delete',
    urlParams: {
      sourceId,
      userId,
    },
  });
};

const deleteCartCoupons = () => {
  return ApiService.action('checkout_cart_remove_all_coupons', {
    method: 'delete',
  });
};

const addCartCoupon = (coupon: string) => {
  return ApiService.action('checkout_cart_apply_coupon', {
    method: 'post',
    data: {
      coupon,
    },
  });
};

const paypalSuccessfulAgreement = (ba_token: string, token: string) => {
  return ApiService.action('paypal_successful_agreement', {
    method: 'get',
    params: {
      ba_token,
      token,
    },
  });
};

const paypalCanceledAgreement = (ba_token: string) => {
  return ApiService.action('paypal_canceled_agreement', {
    method: 'get',
    params: {
      ba_token,
    },
  });
};

export function createPaymentService() {
  const service = {
    init: ApiService.init,
    completeCheckout,
    confirmCheckout,
    addProductToCart,
    getSubscriptionDetails,
    getPaymentSources,
    createPaymentSource,
    setDefaultPaymentSource,
    deletePaymentSource,
    deleteCartCoupons,
    addCartCoupon,
    paypalSuccessfulAgreement,
    paypalCanceledAgreement,
  };
  return service;
}

const paymentService = createPaymentService();
export default paymentService;
