import type { IAppState } from '../state';
import type { Action } from '../actions';

import {
  I18NEXT_INIT,
  I18NEXT_READY,
  VALIDATE_ACCESS_TOKEN_REQUEST,
  VALIDATE_ACCESS_TOKEN_SUCCESS,
  I18NEXT_FAIL,
  APP_LINKS_UPDATE,
  APP_START_FAILED,
  APP_START,
  APP_STARTED,
  VALIDATE_ACCESS_TOKEN_FAIL,
  APP_OPEN_TOU,
  APP_CLOSE_TOU,
  USER_LOAD,
} from '../actions';
import { prepareUserData } from '../../utils';

export const initState: IAppState = {
  loading: 0,
  backUrl: null,
  completeUrl: null,
  showTou: false,
  user: null,
};

export default function appReducer(state: IAppState = initState, action: Action): IAppState {
  const { type, payload } = action;
  switch (type) {
    case I18NEXT_INIT:
    case APP_START:
    case VALIDATE_ACCESS_TOKEN_REQUEST:
      return Object.assign({}, state, {
        loading: state.loading + 1,
      });
    case I18NEXT_READY:
    case APP_STARTED:
    case APP_START_FAILED:
    case I18NEXT_FAIL: {
      return Object.assign({}, state, {
        loading: state.loading - 1,
      });
    }
    case VALIDATE_ACCESS_TOKEN_SUCCESS:
      const session = action.payload;
      return Object.assign({}, state, {
        loading: state.loading - 1,
        session,
      });
    case APP_LINKS_UPDATE:
      return Object.assign({}, state, {
        backUrl: payload.backUrl,
        completeUrl: payload.completeUrl,
      });
    case VALIDATE_ACCESS_TOKEN_FAIL:
      return Object.assign({}, state, {
        loading: state.loading - 2,
        user: { ...state.user, isAuthorized: false },
      });
    case APP_OPEN_TOU:
      return { ...state, showTou: true };
    case APP_CLOSE_TOU:
      return { ...state, showTou: false };
    case USER_LOAD: {
      if (!action.payload?.user) {
        return { ...state, user: null };
      }

      const user = prepareUserData(action.payload.user);

      return { ...state, user };
    }
    default:
      return state;
  }
}
