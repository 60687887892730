import ApiService from '../core/apiService';
import { ITicketProps } from './types';

function sendTicket(data: ITicketProps) {
  const userId = ApiService.getUserId();
  if (!userId) {
    throw [new Error('common:Errors.Unauthorized')]; // eslint-disable-line
  }
  return ApiService.action('ticket', {
    method: 'post',
    params: {
      userId,
    },
    data,
  });
}

export function createTicketService() {
  const service = {
    init: ApiService.init,
    sendTicket,
  };
  return service;
}

const ticketService = createTicketService();
export default ticketService;
