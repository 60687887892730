// @flow
import type { PaymentState } from '../state';
import type { Action, SuccessResponseAction } from '../actions';

import {
  GET_PAYMENT_SOURCES_SUCCESS,
  SELECT_PAYMENT_METHOD,
  DELETE_PAYMENT_SOURCE_SUCCESS,
  SET_DEFAULT_PAYMENT_SOURCE_SUCCESS,
  ADD_NEW_PAYMENT_METHOD,
  SELECT_NEW_PAYMENT_METHOD,
  PAYMENT_SAVE_AGREE_UPDATE,
  COMPLETE_CHECKOUT_SUCCESS,
  CHECKOUT_FINISHED,
  ADD_TRAINING_ASSISTANT_SUCCESS,
  UPDATE_PAYMENT_METHODS_LIST,
} from '../actions';

export const initState: PaymentState = {
  loaded: false,
  paymentSources: [],
  defaultSourceId: null,
  selectedSource: null,
  paymentMethod: null,
  defaultSave: false,
  save: false,
  order: null,
  paymentTypes: [],
};

export default function paymentReducer(state: PaymentState = initState, action: Action | SuccessResponseAction) {
  switch (action.type) {
    case GET_PAYMENT_SOURCES_SUCCESS:
      if (action.payload.response.status === 200) {
        const paymentSources = action.payload.response.data.paymentSources;
        const defaultSourceId = action.payload.response.data.defaultSourceId;
        // initially selected source == default source
        const selectedSource = action.payload.response.data.defaultSourceId;
        // new payment method
        const paymentMethod = paymentSources.length > 0 ? state.paymentMethod : null;

        return Object.assign({}, state, {
          loaded: true,
          paymentSources,
          defaultSourceId,
          selectedSource,
          paymentMethod,
        });
      }
      return state;
    // deselect any source
    case ADD_NEW_PAYMENT_METHOD:
      return Object.assign({}, state, { selectedSource: null, paymentMethod: null });
    // select source
    case SELECT_PAYMENT_METHOD:
      return Object.assign({}, state, { selectedSource: action.payload, paymentMethod: null });
    // remove source from list
    case DELETE_PAYMENT_SOURCE_SUCCESS:
      if (action.payload.response.status === 200) {
        const paymentSources = state.paymentSources.filter((source) => {
          return action.payload.sourceId !== source.id;
        });
        return Object.assign({}, state, {
          paymentSources,
          paymentMethod: paymentSources.length > 0 ? state.paymentMethod : 'card',
        });
      }
      return state;
    // set source as default
    case SET_DEFAULT_PAYMENT_SOURCE_SUCCESS:
      if (action.payload.response.status === 200) {
        return Object.assign({}, state, { defaultSourceId: action.payload.sourceId });
      }
      return state;
    // set new payment method as selected
    case SELECT_NEW_PAYMENT_METHOD: {
      let save = state.defaultSave;
      if (action.payload === 'card' || action.payload === 'paypal' || action.payload === 'sepa_debit') {
        save = true;
      }
      return Object.assign({}, state, {
        paymentMethod: action.payload,
        save,
      });
    }
    case PAYMENT_SAVE_AGREE_UPDATE:
      return Object.assign({}, state, { save: action.payload.agree });
    case COMPLETE_CHECKOUT_SUCCESS:
      return Object.assign({}, state, { order: action.payload.order });
    case CHECKOUT_FINISHED:
      return Object.assign({}, state, { order: action.payload.order });
    case ADD_TRAINING_ASSISTANT_SUCCESS:
      return Object.assign({}, state, { order: null, paymentMethod: null });
    case UPDATE_PAYMENT_METHODS_LIST:
      return Object.assign({}, state, { paymentTypes: action.payload });
    default:
      return state;
  }
}
