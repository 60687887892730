// import type { PackagesState } from '../state';
// import type { Action } from '../actions/types';
import { EProductType } from '../../types/product';
import { GET_RELATED_PACKAGES_SUCCESS, ADD_TRAINING_ASSISTANT_SUCCESS } from '../actions';

export const initState /*: PackagesState*/ = {
  baseProduct: null,
  recommendedPackage: null,
  relatedPackages: [],
};

export default function packagesReducer(state /* : PackagesState */ = initState, action /* : Action */) {
  const { type, payload } = action;
  switch (type) {
    case GET_RELATED_PACKAGES_SUCCESS: {
      if (payload && payload.products.length > 0) {
        // cart product ID
        const productId = parseInt(payload.productId);

        // all products related with the requested product
        const relatedProducts = payload.products;

        // get details of the requested product
        let baseProduct = relatedProducts.find((product) => product.id === productId);

        // if the requested product is a group package, get it's base product
        if (baseProduct.type === EProductType.GROUP_ONE_TIME) {
          baseProduct = relatedProducts.find((product) => product.type === EProductType.ONE_TIME);
        } else if (baseProduct.type === EProductType.GROUP_SUBSCRIPTION) {
          baseProduct = relatedProducts.find((product) => product.type === EProductType.SUBSCRIPTION);
        }

        // get group package type related with the base product type
        const packagesType =
          baseProduct.type === EProductType.ONE_TIME ? EProductType.GROUP_ONE_TIME : EProductType.GROUP_SUBSCRIPTION;

        // get all group packages of the respective type
        const relatedPackages = relatedProducts.filter((product) => {
          return product.type === packagesType;
        });

        // get recommended package
        let recommendedPackage = relatedPackages.find((product) => product.recommended);
        if (!recommendedPackage) {
          // if no recommended package were given - take the minimal users package
          recommendedPackage = relatedPackages.sort(
            (productA, productB) => productA.maxGroupUsers - productB.maxGroupUsers
          )[0];
        }

        return Object.assign({}, state, {
          baseProduct,
          recommendedPackage,
          relatedPackages,
        });
      }

      return state;
    }
    case ADD_TRAINING_ASSISTANT_SUCCESS:
      return Object.assign({}, state, {
        baseProduct: null,
        recommendedPackage: null,
        relatedPackages: [],
      });
    default:
      return state;
  }
}
