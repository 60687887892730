//@flow
import type { INotification } from '../../types/notification';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const addNotificationAction = (notification: INotification) => {
  return {
    type: ADD_NOTIFICATION,
    payload: {
      notification,
    },
  };
};

export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const removeNotificationAction = (notificationId: number) => {
  return {
    type: REMOVE_NOTIFICATION,
    payload: {
      notificationId,
    },
  };
};
