export const DEFAULT_MESSAGE_DELAY = 7000;
export const STRIPE_METHOD_IDS = [5, 7];
export const DISCOUNT_AMOUNT = 30;

export const DASHBOARD_PATH = '/dashboard';

export const EVALUATION_PATH = '/evaluation';

export const PERSONALIZATION_PATH = '/personalization';

export const FRIENDS_PATH = '/friends';

export const MESSAGES_PATH = '/messages';
