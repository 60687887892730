import {
  ADD_NEW_TICKET,
  ADD_NEW_TICKET_FAILURE,
  ADD_NEW_TICKET_RESET,
  ADD_NEW_TICKET_SUCCESS,
  TTicketActions,
} from './actionTypes';

export interface ITicketState {
  isSent: boolean;
}

export const initState: ITicketState = {
  isSent: false,
};

export const statePropName = 'ticket';

export default function ticketReducer(state: ITicketState = initState, action: TTicketActions) {
  switch (action.type) {
    case ADD_NEW_TICKET_FAILURE:
    case ADD_NEW_TICKET: {
      return { ...state, isSent: false };
    }
    case ADD_NEW_TICKET_SUCCESS: {
      return { ...state, isSent: true };
    }
    case ADD_NEW_TICKET_RESET: {
      return initState;
    }
    default:
      return state;
  }
}
