import type { FormValidationError } from '../../types/error';
import type StripeError from '../../../helpers/stripeError';
import type { StripeErrorAction } from './types';

// UI interaction actions
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD';
export const ADD_NEW_PAYMENT_METHOD = 'ADD_NEW_PAYMENT_METHOD';
export const SELECT_NEW_PAYMENT_METHOD = 'SELECT_NEW_PAYMENT_METHOD';

export const PAYMENT_VALIDATION_ERROR = 'PAYMENT_VALIDATION_ERROR';
export const PAYMENT_STRIPE_ERROR = 'PAYMENT_STRIPE_ERROR';
export const PAYMENT_VALIDATION_ERROR_CLEAR = 'PAYMENT_VALIDATION_ERROR_CLEAR';

export const PAYMENT_SAVE_AGREE_UPDATE = 'PAYMENT_SAVE_AGREE_UPDATE';
export const PAYMENT_DEFAULT_SAVE_UPDATE = 'PAYMENT_DEFAULT_SAVE_UPDATE';

// API requests actions

export const CREATE_PAYMENT_REQUEST_METHOD = 'CREATE_PAYMENT_REQUEST_METHOD';
export const CREATE_PAYMENT_METHOD_SUCCESS = 'CREATE_PAYMENT_METHOD_SUCCESS';
export const CREATE_PAYMENT_METHOD_FAIL = 'CREATE_PAYMENT_METHOD_FAIL';

export const GET_PAYMENT_SOURCES_REQUEST = 'GET_PAYMENT_SOURCES_REQUEST';
export const GET_PAYMENT_SOURCES_SUCCESS = 'GET_PAYMENT_SOURCES_SUCCESS';
export const GET_PAYMENT_SOURCES_FAIL = 'GET_PAYMENT_SOURCES_FAIL';

export const DELETE_PAYMENT_SOURCE_REQUEST = 'DELETE_PAYMENT_SOURCE_REQUEST';
export const DELETE_PAYMENT_SOURCE_SUCCESS = 'DELETE_PAYMENT_SOURCE_SUCCESS';
export const DELETE_PAYMENT_SOURCE_FAIL = 'DELETE_PAYMENT_SOURCE_FAIL';

export const SET_DEFAULT_PAYMENT_SOURCE_REQUEST = 'SET_DEFAULT_PAYMENT_SOURCE_REQUEST';
export const SET_DEFAULT_PAYMENT_SOURCE_SUCCESS = 'SET_DEFAULT_PAYMENT_SOURCE_SUCCESS';
export const SET_DEFAULT_PAYMENT_SOURCE_FAIL = 'SET_DEFAULT_PAYMENT_SOURCE_FAIL';

// Paypal related actions
export const PAYPAL_SUCCESSFUL_AGREEMENT_METHOD = 'PAYPAL_SUCCESSFUL_AGREEMENT_METHOD';
export const SAVE_PAYPAL_AGREEMENT_SUCCESS = 'SAVE_PAYPAL_AGREEMENT_SUCCESS';
export const SAVE_PAYPAL_AGREEMENT_FAIL = 'SAVE_PAYPAL_AGREEMENT_FAIL';

export const PAYPAL_CANCELED_AGREEMENT_METHOD = 'PAYPAL_CANCELED_AGREEMENT_METHOD';
export const PAYPAL_CANCELED_AGREEMENT_SUCCESS = 'PAYPAL_CANCELED_AGREEMENT_SUCCESS';
export const PAYPAL_CANCELED_AGREEMENT_FAIL = 'PAYPAL_CANCELED_AGREEMENT_FAIL';

export const GET_PAYMENT_METHODS_LIST = 'GET_PAYMENT_METHODS_LIST';
export const UPDATE_PAYMENT_METHODS_LIST = 'UPDATE_PAYMENT_METHODS_LIST';

export const selectPaymentMethodAction = (sourceId: string | null) => {
  return {
    type: SELECT_PAYMENT_METHOD,
    payload: sourceId,
  };
};

export const addNewPaymentMethodAction = () => {
  return {
    type: ADD_NEW_PAYMENT_METHOD,
  };
};

export const selectNewPaymentMethodAction = (value: any) => {
  return {
    type: SELECT_NEW_PAYMENT_METHOD,
    payload: value,
  };
};

export const validationErrorAction = (error: FormValidationError) => {
  return {
    type: PAYMENT_VALIDATION_ERROR,
    payload: error,
  };
};

export const stripeErrorAction: (err: StripeError) => StripeErrorAction = (error) => {
  return {
    type: PAYMENT_STRIPE_ERROR,
    payload: error,
  };
};

export const validationErrorClearAction = (input: string) => {
  return {
    type: PAYMENT_VALIDATION_ERROR_CLEAR,
    payload: input,
  };
};

export const saveAgreeUpdateAction = (agree: boolean) => {
  return {
    type: PAYMENT_SAVE_AGREE_UPDATE,
    payload: {
      agree,
    },
  };
};

export const defaultSaveUpdateAction = (agree: boolean) => {
  return {
    type: PAYMENT_DEFAULT_SAVE_UPDATE,
    payload: {
      agree,
    },
  };
};

export const createPaymentMethodRequestAction = (token: any) => {
  return {
    type: CREATE_PAYMENT_REQUEST_METHOD,
    payload: {
      token,
    },
  };
};

export const createPaymentSuccessAction = (response: any) => {
  return {
    type: CREATE_PAYMENT_METHOD_SUCCESS,
    payload: {
      response,
    },
  };
};

export const createPaymentFailAction = (error: any) => {
  return {
    type: CREATE_PAYMENT_METHOD_FAIL,
    payload: { error },
  };
};

export const getPaymentSourcesRequestAction = () => {
  return {
    type: GET_PAYMENT_SOURCES_REQUEST,
  };
};

export const getPaymentSourcesSuccessAction = (response: any) => {
  return {
    type: GET_PAYMENT_SOURCES_SUCCESS,
    payload: {
      response,
    },
  };
};

export const getPaymentSourcesFailAction = (error: any) => {
  return {
    type: GET_PAYMENT_SOURCES_FAIL,
    payload: { error },
  };
};

export const deletePaymentSourceRequestAction = (sourceId: any) => {
  return {
    type: DELETE_PAYMENT_SOURCE_REQUEST,
    payload: {
      sourceId,
    },
  };
};

export const deletePaymentSourceSuccessAction = (response: any, sourceId: any) => {
  return {
    type: DELETE_PAYMENT_SOURCE_SUCCESS,
    payload: {
      response,
      sourceId,
    },
  };
};

export const deletePaymentSourceFailAction = (error: any) => {
  return {
    type: DELETE_PAYMENT_SOURCE_FAIL,
    payload: {
      error,
    },
  };
};

export const setDefaultPaymentSourceRequestAction = (sourceId: string, type: string) => {
  return {
    type: SET_DEFAULT_PAYMENT_SOURCE_REQUEST,
    payload: {
      sourceId,
      type,
    },
  };
};

export const setDefaultPaymentSourceSuccessAction = (response: any, sourceId: any) => {
  return {
    type: SET_DEFAULT_PAYMENT_SOURCE_SUCCESS,
    payload: {
      response,
      sourceId,
    },
  };
};

export const setDefaultPaymentSourceFailAction = (error: any) => {
  return {
    type: SET_DEFAULT_PAYMENT_SOURCE_FAIL,
    payload: {
      error,
    },
  };
};

export const paypalSuccessfulAgreementAction = (ba_token: string, token: string) => {
  return {
    type: PAYPAL_SUCCESSFUL_AGREEMENT_METHOD,
    payload: {
      ba_token,
      token,
    },
  };
};

export const savePaypalAgreementSuccessAction = (response: any) => {
  return {
    type: SAVE_PAYPAL_AGREEMENT_SUCCESS,
    payload: {
      response,
    },
  };
};

export const savePaypalAgreementFailAction = (error: any) => {
  return {
    type: SAVE_PAYPAL_AGREEMENT_FAIL,
    payload: { error },
  };
};

export const paypalCanceledAgreementAction = (ba_token: string) => {
  return {
    type: PAYPAL_CANCELED_AGREEMENT_METHOD,
    payload: {
      ba_token,
    },
  };
};

export const paypalCanceledAgreementSuccessAction = (response: any) => {
  return {
    type: PAYPAL_CANCELED_AGREEMENT_SUCCESS,
    payload: {
      response,
    },
  };
};

export const paypalCanceledAgreementFailAction = (error: any) => {
  return {
    type: PAYPAL_CANCELED_AGREEMENT_FAIL,
    payload: { error },
  };
};

export const getPaymentMethodsListAction = () => ({
  type: GET_PAYMENT_METHODS_LIST,
});

export const updatePaymentMethodsListAction = (methods: string[]) => ({
  type: UPDATE_PAYMENT_METHODS_LIST,
  payload: methods,
});
