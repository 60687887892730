import { put, call, takeEvery, all } from 'redux-saga/effects';
import {
  GET_ADDRESSES_REQUEST,
  SAVE_ADDRESS_REQUEST,
  SAVE_ADDRESS_SUCCESS,
  getAddressesSuccessAction,
  getAddressesFailAction,
  saveAddressSuccessAction,
  saveAddressFailAction,
  getAddressesRequestAction,
} from '../actions';
import AddressService from '../../../services/addressService';

export function* handleGetAddressesRequest() {
  try {
    const response = yield call(AddressService.getUserAddresses);
    yield put(getAddressesSuccessAction(response));
  } catch (error) {
    yield put(getAddressesFailAction(error));
  }
}

export function* handleSavingAddress(action) {
  try {
    const address = action.payload.address;
    const response = yield call(AddressService.saveUserAddress, address);
    yield put(saveAddressSuccessAction(response));
  } catch (error) {
    yield put(saveAddressFailAction(error));
  }
}

export function* reloadAddresses() {
  yield put(getAddressesRequestAction());
}

export default function* watchAddress() {
  yield all([
    takeEvery(GET_ADDRESSES_REQUEST, handleGetAddressesRequest),
    takeEvery(SAVE_ADDRESS_REQUEST, handleSavingAddress),
    takeEvery(SAVE_ADDRESS_SUCCESS, reloadAddresses),
  ]);
}
