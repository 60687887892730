import UrlParser from './urlParser';
import sStorage from './sessionStorage';

export function retrieveParameter(paramName) {
  const savedParam = localStorage.getItem(paramName);
  const search = UrlParser.getCurrentUrlSearch();
  const receivedParam = UrlParser.getUrlSearchValue(search, paramName);

  // value not found
  if (!savedParam && !receivedParam) {
    return;
  }

  if (receivedParam) {
    // use value from url if found
    localStorage.setItem(paramName, receivedParam);
    UrlParser.replaceQuerystring(UrlParser.removeUrlSearchParam(search, paramName));

    return receivedParam;
  }

  // use version saved in local storage if not found in url
  return savedParam;
}

export function getSessionToken() {
  const savedParam = sStorage.getSessionToken();
  const search = UrlParser.getCurrentUrlSearch();
  const receivedParam = UrlParser.getUrlSearchValue(search, UrlParams.SessionToken);
  // value not found
  if (!savedParam && !receivedParam) {
    return;
  }

  if (receivedParam) {
    // use value from url if found
    sStorage.setSessionToken(receivedParam);
    UrlParser.replaceQuerystring(UrlParser.removeUrlSearchParam(search, UrlParams.SessionToken));

    return receivedParam;
  }

  // use version saved in session storage if not found in url
  return savedParam;
}

export function setSessionToken(token /* : string */) {
  sStorage.setSessionToken(token);
}

export function removeSessionToken() {
  sStorage.removeSessionToken();
}

export function clearParameter(paramName) {
  // remove from local storage
  localStorage.removeItem(paramName);
  // remove from url
  const search = UrlParser.getCurrentUrlSearch();
  UrlParser.replaceQuerystring(UrlParser.removeUrlSearchParam(search, paramName));
}

export function getTaboolaClickId() {
  const search = UrlParser.getCurrentUrlSearch();
  const searchId = UrlParser.retrieveTaboolaClickId(search);
  const storedId = sessionStorage.getItem(UrlParams.TaboolaClickId);
  if (!storedId && !searchId) {
    // value not found
    return '';
  }
  if (!!searchId) {
    // use value from url if found and save it in session
    sessionStorage.setItem(UrlParams.TaboolaClickId, searchId);
    return searchId;
  }
  // use version saved in session storage if not found in url
  return storedId || '';
}

export function getOutbrainClickId() {
  const search = UrlParser.getCurrentUrlSearch();
  const searchId = UrlParser.retrieveOutbrainClickId(search);
  const storedId = sessionStorage.getItem(UrlParams.OutbrainId);
  if (!storedId && !searchId) {
    // value not found
    return '';
  }
  if (!!searchId) {
    // use value from url if found and save it in session
    sessionStorage.setItem(UrlParams.OutbrainId, searchId);
    return searchId;
  }
  // use version saved in session storage if not found in url
  return storedId || '';
}

export const UrlParams = {
  // add signin to backUrl and completeUrl
  Forwarding: 'forwardAuth',
  // add session to backUrl and completeUrl
  ForwardSession: 'forwardSession',
  // back link url
  BackUrl: 'backUrl',
  // 'start training' button link
  CompleteUrl: 'completeUrl',
  // access/session token parameters
  // access token is still used by legacy system (legacy.neuronation.com)
  AccessToken: 'signin',
  SessionToken: 'session',
  // discount coupon
  Coupon: 'coupon',
  // product id to add to cart on start
  Product: 'productId',
  // skip/remove coupon for this product
  NoCoupon: 'noCoupon',
  // payment related params
  Method: 'method',
  Success: 'success',
  Token: 'token',
  BaToken: 'ba_token',
  TaboolaClickId: 'taboola_click_id',
  OutbrainId: 'ob_click_id',
  SessionId: 'sessionId',
};
