import * as ActionTypes from './actionTypes';

// initialize feature
export const initLanguageAction: () => ActionTypes.ILanguageInitAction = () => {
  return {
    type: ActionTypes.LANGUAGE_INIT,
  };
};

// request to change language
export const changeLanguageAction: (language: string) => ActionTypes.IChangeLanguageAction = (language) => {
  return {
    type: ActionTypes.LANGUAGE_CHANGE,
    language,
  };
};

// language detected or changed - update state
export const updateLanguageAction: (language?: string) => ActionTypes.IUpdateLanguageAction = (language) => {
  return {
    type: ActionTypes.LANGUAGE_UPDATE,
    language,
  };
};

// loading/updating of the feature failed
export const languageFailAction: (error: Error) => ActionTypes.ILanguageFailAction = (error) => {
  return {
    type: ActionTypes.LANGUAGE_FAIL,
    error,
  };
};
