import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { APP_START, VALIDATE_ACCESS_TOKEN_SUCCESS, ACCESS_TOKEN_FOUND, userLoadAction, AUTH_LOGOUT } from '../actions';
import { LanguageSelectors } from '../../../language';

import {
  appStartedAction,
  updateLinksAction,
  validateAccessTokenRequestAction,
  appStartFailedAction,
} from '../actions';
import AppService from '../services/appService';

import paymentService from '../../../services/paymentService';
import AddressService from '../../../services/addressService';
import SubscriptionService from '../../../services/subscriptionService';

import { getOutbrainClickId, getTaboolaClickId, UrlParams } from '../../../helpers/parameters';
import UrlParser from '../../../helpers/urlParser';
import * as Selectors from '../selectors/appSelectors';
import { FirebaseActions } from '../../../firebase';
import { OrdersActions } from '../../../orders';
import { GdprActions } from '../../../gdpr';
import { addRetry } from '../../apiService';
import { redirectLogout } from '../../historyService';

export function* handleAppStart() {
  // add retry feature to all following calls to api
  yield call(addRetry);
  yield put(GdprActions.initGdprAction());
  const search = UrlParser.getCurrentUrlSearch();
  const backUrl = UrlParser.retrieveBackUrl(search);
  const completeUrl = UrlParser.retrieveCompleteUrl(search);
  // update initial links in state
  yield put(updateLinksAction(backUrl, completeUrl));
  // detect taboola
  yield fork(detectTaboola);
  // detect outbrain
  yield fork(detectOutbrain);

  // start access token validation
  yield put(validateAccessTokenRequestAction());
}

export function* handleAccessTokenForwarding(action) {
  // check if access token forwarding is needed
  const search = UrlParser.getCurrentUrlSearch();
  const forwarding = UrlParser.getForwarding(search);
  if (!forwarding) {
    return;
  }
  // get token
  const accessToken = action.payload;
  if (!accessToken) {
    return;
  }
  // get back url
  let backUrl = yield select(Selectors.getBackUrl);
  // add token to url if its set
  if (backUrl) {
    backUrl = UrlParser.addUrlSearchParam(backUrl, UrlParams.AccessToken, accessToken);
  }
  // get complete url
  let completeUrl = yield select(Selectors.getCompleteUrl);
  // add token to url if its set
  if (completeUrl) {
    completeUrl = UrlParser.addUrlSearchParam(completeUrl, UrlParams.AccessToken, accessToken);
  }
  // update links in state
  yield put(updateLinksAction(backUrl, completeUrl));
}

export function* handleSessionTokenForwarding(action) {
  // check if session token forwarding is needed
  const search = UrlParser.getCurrentUrlSearch();
  const forwarding = UrlParser.getSessionForwarding(search);
  if (!forwarding) {
    return;
  }
  // get token
  const sessionToken = action.payload;
  if (!sessionToken) {
    return;
  }
  // get back url
  let backUrl = yield select(Selectors.getBackUrl);
  // add token to url if its set
  if (backUrl) {
    backUrl = UrlParser.addUrlSearchParam(backUrl, UrlParams.SessionToken, sessionToken);
  }
  // get complete url
  let completeUrl = yield select(Selectors.getCompleteUrl);
  // add token to url if its set
  if (completeUrl) {
    completeUrl = UrlParser.addUrlSearchParam(completeUrl, UrlParams.SessionToken, sessionToken);
  }
  // update links in state
  yield put(updateLinksAction(backUrl, completeUrl));
}

export function* loadUserData() {
  const userResponse = yield call(AppService.getUserRoles);
  const user = userResponse.data?.user || null;

  yield put(userLoadAction(user));
}

export function* loadAppData(action) {
  try {
    // initialize app services with session token
    const token = action.payload;
    yield call(paymentService.init, token);
    yield call(AddressService.init, token);
    yield call(SubscriptionService.init, token);
    yield call(AppService.init, token);
    yield put(OrdersActions.initOrdersAction(token));

    yield put(appStartedAction());

    yield call(loadUserData);
  } catch (error) {
    yield put(appStartFailedAction(error.message));
  }
}

export function* detectTaboola() {
  const taboolaClickId = yield call(getTaboolaClickId);
  yield put(FirebaseActions.initTaboolaAction(taboolaClickId));
}

export function* detectOutbrain() {
  const outbrainClickId = yield call(getOutbrainClickId);
  yield put(FirebaseActions.initOutbrainAction(outbrainClickId));
}

export function* logoutSaga() {
  const language = yield select(LanguageSelectors.getLanguage);
  redirectLogout(language);

  return;
}

export default function* watchApp() {
  yield all([
    takeEvery(APP_START, handleAppStart),
    takeEvery(VALIDATE_ACCESS_TOKEN_SUCCESS, loadAppData),
    takeEvery(VALIDATE_ACCESS_TOKEN_SUCCESS, handleSessionTokenForwarding),
    takeEvery(ACCESS_TOKEN_FOUND, handleAccessTokenForwarding),
    takeEvery(AUTH_LOGOUT, logoutSaga),
  ]);
}
