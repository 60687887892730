import * as ActionTypes from './actionTypes';
import { IRemoteConfig } from './config';
import { IEventActionPayload } from './types';

// init Firebase and GA
export const firebaseInitAction: (sessionId?: string) => ActionTypes.IFirebaseInitAction = (sessionId) => ({
  type: ActionTypes.FIREBASE_INIT,
  sessionId,
});

// set up Firebase and GA with users consent
export const firebaseStartAction: (analytics: boolean, optimize?: boolean) => ActionTypes.IFirebaseStartAction = (
  analytics,
  optimize
) => ({
  type: ActionTypes.FIREBASE_START,
  analytics,
  optimize,
});

// Firebase is ready and all apps are initialized
export const firebaseReadyAction: () => ActionTypes.IFirebaseReadyAction = () => ({
  type: ActionTypes.FIREBASE_READY,
});

// stop using cookies according to consent
export const disableCookiesAction: () => ActionTypes.IDisableFirebaseAction = () => ({
  type: ActionTypes.FIREBASE_DISABLE,
});

// send an user action / response event to Firebase
export const analyticsEventAction: (analyticsEvent: IEventActionPayload) => ActionTypes.IAnalyticsEventAction = (
  analyticsEvent
) => ({
  type: ActionTypes.ANALYTICS_EVENT,
  analyticsEvent,
});

// update remote config fetched from firebase
export const updateRemoteConfigAction: (config: IRemoteConfig) => ActionTypes.IUpdateRemoteConfigAction = (config) => ({
  type: ActionTypes.UPDATE_REMOTE_CONFIG,
  config,
});

// send an user action / response event to custom GA instance
export const gaEventAction: (analyticsEvent: IEventActionPayload) => ActionTypes.IGAEventAction = (analyticsEvent) => ({
  type: ActionTypes.GA_EVENT,
  analyticsEvent,
});

export const trackCompletedAction = (
  packageId: string,
  packageName: string,
  currency: string,
  origPrice: number,
  actualPrice: number
) => {
  const firebasePayload = {
    eventName: 'm_confirm',
    parameters: [
      {
        key: 'Package ID',
        value: packageId,
      },
      {
        key: 'Package Name',
        value: packageName,
      },
      {
        key: 'Currency',
        value: currency,
      },
      {
        key: 'Original Price',
        value: origPrice,
      },
      {
        key: 'Actual Price',
        value: actualPrice,
      },
    ],
  };
  return analyticsEventAction(firebasePayload);
};

export const trackCompletedWithGAAction = (
  packageId: string,
  packageName: string,
  currency: string,
  origPrice: number,
  actualPrice: number
) => {
  const firebasePayload = {
    eventName: 'm_confirm',
    parameters: [
      {
        key: 'Package ID',
        value: packageId,
      },
      {
        key: 'Package Name',
        value: packageName,
      },
      {
        key: 'Currency',
        value: currency,
      },
      {
        key: 'Original Price',
        value: origPrice,
      },
      {
        key: 'Actual Price',
        value: actualPrice,
      },
    ],
  };
  return gaEventAction(firebasePayload);
};

export const trackCompletedWithGA4Action = (currency: string, actualPrice: number) => {
  const firebasePayload = {
    eventName: 'purchase',
    parameters: [
      {
        key: 'currency',
        value: currency,
      },
      {
        key: 'value',
        value: actualPrice,
      },
    ],
  };

  return analyticsEventAction(firebasePayload);
};

export const initTaboolaAction: (taboolaClickId?: string) => ActionTypes.IInitTaboolaAction = (taboolaClickId) => ({
  type: ActionTypes.TABOOLA_INIT,
  taboolaClickId,
});

export const trackTaboolaPurchaseEventAction: (
  revenue: number,
  currency: string
) => ActionTypes.ITaboolaPurchaseEventAction = (revenue, currency) => ({
  type: ActionTypes.TABOOLA_PURCHASE_EVENT,
  revenue,
  currency,
});

export const initOutbrainAction: (outbrainClickId?: string) => ActionTypes.IInitOutbrainAction = (outbrainClickId) => ({
  type: ActionTypes.OUTBRAIN_INIT,
  outbrainClickId,
});

export const trackOutbrainPurchaseEventAction: (
  revenue: number,
  currency: string
) => ActionTypes.IOutbrainPurchaseEventAction = (revenue, currency) => ({
  type: ActionTypes.OUTBRAIN_PURCHASE_EVENT,
  revenue,
  currency,
});

// set userId when available
export const setUserIdAction: (userId: number) => ActionTypes.ISetUserIdAction = (userId) => ({
  type: ActionTypes.SET_USERID,
  userId,
});

export const trackRestoreAction = () => {
  const firebasePayload = {
    eventName: 'u_restore_button',
  };
  return analyticsEventAction(firebasePayload);
};
