import i18next from 'i18next';
import React from 'react';
import { Button, EButtonColor } from 'web_opxp_components';

export interface IConsentBannerProps {
  privacyUrl: string;
  show: boolean;
  onAcceptAll: () => void;
  onToggleSettings: () => void;
}

export const ConsentBanner: React.FC<IConsentBannerProps> = ({ privacyUrl, show, onAcceptAll, onToggleSettings }) => {
  if (!show) {
    return null;
  }
  const privacyPolicyLink = `<a href="${privacyUrl}" rel="noopener noreferrer" target="_blank">${i18next.t(
    'common.common:Cookies.LinkLabel'
  )}</a>`;
  const cookieNote = i18next.t('common.common:Cookies.Notice', { privacyPolicyLink });

  return (
    <div className="cookie-consent-banner">
      <div className="container cookie-consent-banner-container">
        <div className="row">
          <div className="col-6 col-m-4">
            <p className="cookie-consent-content" dangerouslySetInnerHTML={{ __html: cookieNote }} />
          </div>
          <div className="col-6 col-m-4">
            <div className="buttons-container">
              <Button
                className="button"
                label={i18next.t('common.common:Cookies.CTA.More')}
                color={EButtonColor.white}
                onClick={onToggleSettings}
              />
              <Button
                className="button"
                label={i18next.t('common.common:Cookies.CTA.ActivateAll')}
                color={EButtonColor.blue}
                onClick={onAcceptAll}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConsentBanner;
