import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import service from './service';
import { ILanguageStore } from './types';

export const LanguageContext = createContext<ILanguageStore | undefined>(undefined);

export const LanguageProvider: FC = ({ children }) => {
  const [store, setStore] = useState<ILanguageStore>(service.getStore());
  useEffect(() => {
    const unsubscribe = service.subscribe(setStore);
    return () => {
      unsubscribe();
    };
  }, []);
  return <LanguageContext.Provider value={store}>{children}</LanguageContext.Provider>;
};

export default function useTranslation() {
  const value = useContext(LanguageContext);
  if (!value) {
    throw new Error('This hook must be used only inside the LanguageProvider component!');
  }
  return value;
}

export function withTranslation(component: ReactNode) {
  return <LanguageProvider>{component}</LanguageProvider>;
}
