import { Action } from 'redux';
import { IRemoteConfig } from './config';
import { IEventActionPayload } from './types';

export const FIREBASE_INIT = '@@FIREBASE/FIREBASE_INIT';
export type FIREBASE_INIT_TYPE = typeof FIREBASE_INIT;
export interface IFirebaseInitAction extends Action<FIREBASE_INIT_TYPE> {
  type: FIREBASE_INIT_TYPE;
  sessionId?: string;
}

export const FIREBASE_START = '@@FIREBASE/FIREBASE_START';
export type FIREBASE_START_TYPE = typeof FIREBASE_START;
export interface IFirebaseStartAction extends Action<FIREBASE_START_TYPE> {
  type: FIREBASE_START_TYPE;
  analytics: boolean;
  optimize?: boolean;
}

export const FIREBASE_READY = '@@FIREBASE/FIREBASE_READY';
export type FIREBASE_READY_TYPE = typeof FIREBASE_READY;
export interface IFirebaseReadyAction extends Action<FIREBASE_READY_TYPE> {
  type: FIREBASE_READY_TYPE;
}

export const FIREBASE_DISABLE = '@@FIREBASE/FIREBASE_DISABLE';
export type FIREBASE_DISABLE_TYPE = typeof FIREBASE_DISABLE;
export interface IDisableFirebaseAction extends Action<FIREBASE_DISABLE_TYPE> {
  type: FIREBASE_DISABLE_TYPE;
}

export const ANALYTICS_EVENT = '@@FIREBASE/ANALYTICS_EVENT';
export type ANALYTICS_EVENT_TYPE = typeof ANALYTICS_EVENT;
export interface IAnalyticsEventAction extends Action<ANALYTICS_EVENT_TYPE> {
  type: ANALYTICS_EVENT_TYPE;
  analyticsEvent: IEventActionPayload;
}

export const UPDATE_REMOTE_CONFIG = '@@FIREBASE/UPDATE_REMOTE_CONFIG';
export type UPDATE_REMOTE_CONFIG_TYPE = typeof UPDATE_REMOTE_CONFIG;
export interface IUpdateRemoteConfigAction extends Action<UPDATE_REMOTE_CONFIG_TYPE> {
  type: UPDATE_REMOTE_CONFIG_TYPE;
  config: IRemoteConfig;
}

export const GA_EVENT = '@@FIREBASE/GA_EVENT';
export type GA_EVENT_TYPE = typeof GA_EVENT;
export interface IGAEventAction extends Action<GA_EVENT_TYPE> {
  type: GA_EVENT_TYPE;
  analyticsEvent: IEventActionPayload;
}

export const TABOOLA_INIT = '@@FIREBASE/TABOOLA_INIT';
export type TABOOLA_INIT_TYPE = typeof TABOOLA_INIT;
export interface IInitTaboolaAction extends Action<TABOOLA_INIT_TYPE> {
  type: TABOOLA_INIT_TYPE;
  taboolaClickId?: string;
}

export const TABOOLA_PURCHASE_EVENT = '@@FIREBASE/TABOOLA_PURCHASE_EVENT';
export type TABOOLA_PURCHASE_EVENT_TYPE = typeof TABOOLA_PURCHASE_EVENT;
export interface ITaboolaPurchaseEventAction extends Action<TABOOLA_PURCHASE_EVENT_TYPE> {
  type: TABOOLA_PURCHASE_EVENT_TYPE;
  revenue: number;
  currency: string;
}

export const OUTBRAIN_INIT = '@@FIREBASE/OUTBRAIN_INIT';
export type OUTBRAIN_INIT_TYPE = typeof OUTBRAIN_INIT;
export interface IInitOutbrainAction extends Action<OUTBRAIN_INIT_TYPE> {
  type: OUTBRAIN_INIT_TYPE;
  outbrainClickId?: string;
}

export const OUTBRAIN_PURCHASE_EVENT = '@@FIREBASE/OUTBRAIN_PURCHASE_EVENT';
export type OUTBRAIN_PURCHASE_EVENT_TYPE = typeof OUTBRAIN_PURCHASE_EVENT;
export interface IOutbrainPurchaseEventAction extends Action<OUTBRAIN_PURCHASE_EVENT_TYPE> {
  type: OUTBRAIN_PURCHASE_EVENT_TYPE;
  revenue: number;
  currency: string;
}

export const SET_USERID = '@@FIREBASE/SET_USERID';
export type SET_USERID_TYPE = typeof SET_USERID;
export interface ISetUserIdAction extends Action<SET_USERID_TYPE> {
  type: SET_USERID_TYPE;
  userId: number;
}

export type IFirebaseActions =
  | IFirebaseInitAction
  | IFirebaseStartAction
  | IFirebaseReadyAction
  | IDisableFirebaseAction
  | IAnalyticsEventAction
  | IUpdateRemoteConfigAction
  | IGAEventAction
  | IInitTaboolaAction
  | ITaboolaPurchaseEventAction
  | IInitOutbrainAction
  | IOutbrainPurchaseEventAction
  | ISetUserIdAction;
