export interface ICookieConsent {
  firebase: boolean;
  optimize: boolean;
}

export enum EBannerMode {
  Mandatory = -1,
  NoBanner = 0,
  Postponable = 1,
}
