// @flow
import type { NotificationsState } from '../state';
import type { Action } from '../actions';

import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions';

export const initState: NotificationsState = {
  notifications: [],
};

export default function notificationReducer(state: NotificationsState = initState, action: Action) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const newNotifications = [...state.notifications];
      newNotifications.push(action.payload.notification);
      return { ...state, notifications: newNotifications };
    }
    case REMOVE_NOTIFICATION: {
      const newNotifications = [...state.notifications].filter(
        (notification) => notification.id !== action.payload.notificationId
      );
      return { ...state, notifications: newNotifications };
    }
    default:
      return state;
  }
}
