import ApiService from '../core/apiService';

export function getOutbrainService() {
  let clickId: string | undefined;
  const init = (outbrainClickId?: string) => {
    clickId = outbrainClickId;
  };
  const trackPurchase = (revenue: number, currency: string) => {
    if (!clickId) {
      // do not track if click id not set
      return Promise.resolve();
    }
    return ApiService.action('analytics', {
      method: 'get',
      urlParams: {
        service: 'o',
      },
      params: {
        ob_click_id: clickId,
        name: 'purchase',
        revenue,
        currency,
      },
    });
  };
  return {
    init,
    trackPurchase,
  };
}

const outbrainService = getOutbrainService();
export default outbrainService;
