import { put, takeEvery, all, select, take, call, fork } from 'redux-saga/effects';

import {
  CHECKOUT_START,
  COMPLETE_CHECKOUT,
  SET_DEFAULT_PAYMENT_SOURCE_SUCCESS,
  SET_DEFAULT_PAYMENT_SOURCE_FAIL,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_FAIL,
  SAVE_ADDRESS_SUCCESS,
  SAVE_ADDRESS_FAIL,
  ADD_PRODUCT_TO_CART_SUCCESS,
  CHECKOUT_STARTED,
  CHECKOUT_FAIL,
  SAVE_PAYPAL_AGREEMENT_SUCCESS,
  SAVE_PAYPAL_AGREEMENT_FAIL,
  GET_PAYMENT_SOURCES_SUCCESS,
  ADD_TRAINING_ASSISTANT,
} from '../actions';

import {
  checkoutStartedAction,
  getPaymentSourcesRequestAction,
  addProductToCartRequestAction,
  getRelatedPackagesRequestAction,
  getAddressesRequestAction,
  completeCheckoutRequestAction,
  setDefaultPaymentSourceRequestAction,
  completeCheckoutFailAction,
  checkoutFailAction,
  paypalSuccessfulAgreementAction,
  paypalCanceledAgreementAction,
  saveAgreeUpdateAction,
  addTrainingAssistantSuccess,
  createPaymentMethodRequestAction,
  saveAddressRequestAction,
  checkoutFinishedAction,
  checkoutSubmitAction,
  defaultSaveUpdateAction,
  updateLinksAction,
} from '../actions';

import { cartSelector, isSubmitted, paymentSelector } from './selectors';

import { TrainingAssistantInfo } from '../../types/product';

import { isSubscription } from '../../../helpers/checkCartItemType';
import { retrieveParameter, UrlParams, clearParameter } from '../../../helpers/parameters';
import UrlParser from '../../../helpers/urlParser';
import sStorage from '../../../helpers/sessionStorage';
import { LanguageActions } from '../../../language';

export function* handleCheckoutStart() {
  // init language feature
  yield put(LanguageActions.initLanguageAction());
  // check if checkout was already completed for current session
  const order = sStorage.getOrder();
  if (order) {
    // if order found in session storage - customer has completed checkout already
    yield put(checkoutFinishedAction(order));
    return;
  }
  const isPaypalResponse = UrlParser.isPaypalResponse();
  if (isPaypalResponse) {
    const backUrl = localStorage.getItem(UrlParams.BackUrl);
    const completeUrl = localStorage.getItem(UrlParams.CompleteUrl);
    yield put(updateLinksAction(backUrl, completeUrl));
    yield fork(handlePaypalResponse);
  }

  try {
    // retrieve productId and coupon from local storage or parse url search for them
    const productId = retrieveParameter(UrlParams.Product);
    if (!productId) {
      throw new Error('Errors.Invalid.PRODUCT_NOT_FOUND');
    }
    const skipCoupon = retrieveParameter(UrlParams.NoCoupon);
    let coupon;
    if (skipCoupon) {
      clearParameter(UrlParams.NoCoupon);
      clearParameter(UrlParams.Coupon);
    } else {
      coupon = retrieveParameter(UrlParams.Coupon);
    }
    // start loading required data
    yield put(addProductToCartRequestAction(productId, coupon));
    // wait till success
    yield take(ADD_PRODUCT_TO_CART_SUCCESS);
    yield put(getAddressesRequestAction());
    yield put(getPaymentSourcesRequestAction());
    // wait till success
    yield take(GET_PAYMENT_SOURCES_SUCCESS);

    const cartState = yield select(cartSelector);
    const paymentState = yield select(paymentSelector);

    // if is subscription, the payment method has always to be saved
    // even if it isn't subscription, if there isn't any saved payment method, default check checkbox
    if (isSubscription(cartState.type) || !paymentState.defaultSourceId || !paymentState.selectedSource) {
      yield put(saveAgreeUpdateAction(true));
      yield put(defaultSaveUpdateAction(true));
    }

    yield put(getRelatedPackagesRequestAction(productId));
    yield put(checkoutStartedAction());
  } catch (error) {
    yield put(checkoutFailAction(error.message));
  }
}

export function* setDefaultSource(sourceId, type) {
  yield put(setDefaultPaymentSourceRequestAction(sourceId, type));
  const setDefaultResult = yield take([SET_DEFAULT_PAYMENT_SOURCE_SUCCESS, SET_DEFAULT_PAYMENT_SOURCE_FAIL]);
  return setDefaultResult.type === SET_DEFAULT_PAYMENT_SOURCE_SUCCESS;
}

export function* completeCheckout(action) {
  try {
    // prevent double booking
    const alreadySubmitted = yield select(isSubmitted);
    if (alreadySubmitted) {
      return;
    }

    yield put(checkoutSubmitAction());

    const { address, tokenOrSource } = action.payload;

    // first save the address if given
    if (address) {
      yield put(saveAddressRequestAction(address));
      const saveResult = yield take([SAVE_ADDRESS_SUCCESS, SAVE_ADDRESS_FAIL]);
      if (saveResult.type === SAVE_ADDRESS_FAIL) {
        throw saveResult.payload.error;
      }
    }

    let paymentMethodId, source, method;
    const cartState = yield select(cartSelector);
    const paymentState = yield select(paymentSelector);

    const { save, paymentMethod, selectedSource, defaultSourceId, paymentSources } = paymentState;
    method = paymentMethod;

    if (paymentMethod) {
      // new payment method to save or pay without saving

      if (save || paymentMethod === 'paypal') {
        // save this payment method
        yield put(createPaymentMethodRequestAction(tokenOrSource));
        const saveResult = yield take([CREATE_PAYMENT_METHOD_SUCCESS, CREATE_PAYMENT_METHOD_FAIL]);

        if (saveResult.type === CREATE_PAYMENT_METHOD_FAIL) {
          throw saveResult.payload.error;
        } else if (paymentMethod === 'paypal') {
          return;
        }

        // save as default
        const setDefaultResult = yield call(setDefaultSource, tokenOrSource.id, paymentMethod);
        if (!setDefaultResult) {
          throw {}; // eslint-disable-line
        }
      } else {
        // if it isn't to be saved and default, then set proper key in request
        switch (paymentMethod) {
          case 'card':
            paymentMethodId = tokenOrSource.id;
            break;
          case 'sepa_debit':
            source = tokenOrSource.id;
            break;
          case 'bank_transfer':
            method = 'bank_transfer';
            break;
          default:
            break;
        }
      }
    } else {
      // if existing payment source was selected

      const selectedMethod = paymentSources.find((method) => {
        if (method.id === selectedSource) {
          return method;
        }
        return false;
      });

      if (selectedMethod && selectedMethod.type === 'paypal') {
        method = 'paypal';
      } else if (selectedSource !== defaultSourceId) {
        if (isSubscription(cartState.type)) {
          // try to set default
          const setDefaultResult = yield call(
            setDefaultSource,
            selectedSource,
            selectedMethod ? selectedMethod.type : undefined
          );
          if (!setDefaultResult) {
            throw {}; // eslint-disable-line
          }
        } else {
          // or set the source or paymentMethodId
          if (selectedMethod.type === 'card') {
            method = selectedMethod.type;
            paymentMethodId = selectedSource;
          } else {
            source = selectedSource;
          }
        }
      } else {
        // we need to set parameters even for default payment in case of new intent implementation
        if (selectedMethod.type === 'card') {
          method = selectedMethod.type;
          paymentMethodId = selectedSource;
        }
      }
    }

    yield put(completeCheckoutRequestAction(paymentMethodId, source, method));
  } catch (error) {
    yield put(completeCheckoutFailAction(error));
  }
}

export function* handlePaypalResponse() {
  const successfulTransaction = UrlParser.isSuccessfulPaypalResponse();
  const token = UrlParser.returnAndRemoveUrlSearchParam(UrlParams.Token);
  const ba_token = retrieveParameter(UrlParams.BaToken);

  // wait for all data to be load
  const checkoutResult = yield take([CHECKOUT_STARTED, CHECKOUT_FAIL]);
  if (checkoutResult.type === CHECKOUT_FAIL) {
    return;
  }

  if (successfulTransaction) {
    // in case of successful paypal transaction, save method and proceed to checkout completion

    yield put(paypalSuccessfulAgreementAction(ba_token, token));
    const result = yield take([SAVE_PAYPAL_AGREEMENT_SUCCESS, SAVE_PAYPAL_AGREEMENT_FAIL]);

    if (result.type === SAVE_PAYPAL_AGREEMENT_FAIL) {
      return;
    }

    yield put(getPaymentSourcesRequestAction());
    yield put(completeCheckoutRequestAction(null, null, 'paypal'));
  } else {
    // in case of failed paypal transaction, inform api about it
    yield put(paypalCanceledAgreementAction(ba_token));
  }
}

export function* addTrainingAssistant() {
  // remove current orderfrom session storage
  sStorage.removeOrder();

  // remove coupon from local storage
  clearParameter(UrlParams.NoCoupon);
  clearParameter(UrlParams.Coupon);

  const productId = TrainingAssistantInfo.id;

  // save new product Id on local storage
  localStorage.setItem(UrlParams.Product, productId);

  // add training assistant product to the cart
  yield put(addProductToCartRequestAction(productId));
  // wait till success
  yield take(ADD_PRODUCT_TO_CART_SUCCESS);

  yield put(addTrainingAssistantSuccess());
}

export default function* watchCheckout() {
  yield all([
    takeEvery(CHECKOUT_START, handleCheckoutStart),
    takeEvery(COMPLETE_CHECKOUT, completeCheckout),
    takeEvery(ADD_TRAINING_ASSISTANT, addTrainingAssistant),
  ]);
}
