import { initPlugin, IGTagConfigOptions, IGTagService } from './gtag';

let REACT_APP_GA_ID: string | undefined;
let REACT_APP_OPTIMIZE_ID: string | undefined;

let gtagInstance: IGTagService;

export function initGA(sessionId?: string) {
  REACT_APP_GA_ID = process.env.REACT_APP_GA_ID;
  if (!REACT_APP_GA_ID) {
    throw new Error('Measurement ID not set');
  }
  const options: IGTagConfigOptions = {
    send_page_view: false,
    cookie_expires: 14 * 30 * 24 * 60 * 60, // ~14 monthes, in seconds
    storage: 'none',
    storeGac: false,
    anonymize_ip: true,
  };
  if (!!sessionId) {
    // set client id only if sessionId provided
    options.client_id = sessionId;
  }
  gtagInstance = initPlugin(REACT_APP_GA_ID, options);
}

export function updateGAWithOptimize() {
  if (!gtagInstance) {
    throw new Error('gtag must be initialized first!');
  }
  REACT_APP_OPTIMIZE_ID = process.env.REACT_APP_OPTIMIZE_ID;
  if (!REACT_APP_OPTIMIZE_ID) {
    throw new Error('Optimize ID not set');
  }
  const options: IGTagConfigOptions = {
    update: true,
    optimize_id: REACT_APP_OPTIMIZE_ID,
  };
  gtagInstance.update(options);
}

export function event(name: string, params: any = {}) {
  if (!gtagInstance) {
    throw new Error('gtag must be initialized first!');
  }
  gtagInstance.event(name, params);
}

export function setUserIdForGA(userId: number) {
  if (!gtagInstance) {
    throw new Error('gtag must be initialized first!');
  }
  const options: IGTagConfigOptions = {
    update: true,
    user_id: `${userId}`,
  };
  gtagInstance.update(options);
}
