import * as ActionTypes from './actionTypes';
import { EBannerMode } from './types';

export interface IGdprState {
  // consent for firebase given
  firebase: boolean;
  // consent for google optimize given
  optimize: boolean;
  // user choice is submitted
  submitted: boolean;
  // settings dialog open
  settings: boolean;
  // cookie banner mode
  mode: EBannerMode;
}

export const initialState: IGdprState = {
  firebase: true,
  optimize: true,
  submitted: false,
  settings: false,
  mode: EBannerMode.Postponable,
};

export const statePropName = 'gdpr';

export default function gdprReducer(state: IGdprState = initialState, action: ActionTypes.IGdprActions): IGdprState {
  switch (action.type) {
    case ActionTypes.GDPR_UPDATE_CONSENT: {
      const firebase = !!action.consent.firebase;
      const optimize = !!action.consent.optimize;
      return {
        ...state,
        firebase,
        optimize,
        submitted: true,
        settings: false,
      };
    }
    case ActionTypes.GDPR_SETTINGS_TOGGLE: {
      const settings = !state.settings;
      return {
        ...state,
        settings,
      };
    }
    case ActionTypes.BANNER_MODE_UPDATE: {
      return {
        ...state,
        mode: action.mode,
      };
    }
  }
  return state;
}
