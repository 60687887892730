import * as ActionTypes from './actionTypes';
import { IOrder } from './types';

export interface IOrdersState {
  loaded: boolean;
  orders: IOrder[];
}

export const statePropName = 'orders';

export const initState: IOrdersState = {
  loaded: false,
  orders: [],
};

export default function ordersReducer(state: IOrdersState = initState, action: ActionTypes.IOrdersActions) {
  switch (action.type) {
    case ActionTypes.GET_ORDERS_REQUEST:
      return { ...state, loaded: false, orders: [] };
    case ActionTypes.GET_ORDERS_SUCCESS:
      if (action.payload.response.status === 200) {
        const orders = action.payload.response.data.orders;
        return { ...state, loaded: true, orders };
      } else {
        return { ...state, loaded: true };
      }
    case ActionTypes.GET_ORDERS_FAILED:
      return { ...state, loaded: true };
    default:
      return state;
  }
}
