// @flow
import type { ErrorsState } from '../state';
import type { Action } from '../actions';

import {
  ERROR_CLEAR,
  PAYMENT_VALIDATION_ERROR,
  PAYMENT_VALIDATION_ERROR_CLEAR,
  ADD_COUPON_TO_CART_FAIL,
  ADD_PRODUCT_TO_CART_FAIL,
  SAVE_ADDRESS_FAIL,
  VALIDATE_ACCESS_TOKEN_FAIL,
  CHECKOUT_FAIL,
  CREATE_PAYMENT_METHOD_FAIL,
  COMPLETE_CHECKOUT_FAIL,
  ADD_PRODUCT_TO_CART_WRONG_COUPON,
  GET_PAYMENT_SOURCES_FAIL,
  SAVE_PAYPAL_AGREEMENT_FAIL,
  PAYPAL_CANCELED_AGREEMENT_SUCCESS,
  PAYPAL_CANCELED_AGREEMENT_FAIL,
} from '../actions';

export const initState: ErrorsState = {};

export default function errorsReducer(state: ErrorsState = initState, action: Action) {
  // input id or name where to show error message
  let input = 'unknown';
  const { type, payload } = action;
  switch (type) {
    case CHECKOUT_FAIL: {
      // should not ovveride current error message
      if (!state.root || state.root.length === 0) {
        return Object.assign({}, state, {
          root: payload.error,
        });
      } else return state;
    }
    // apply error information to corresponding input id
    case PAYMENT_VALIDATION_ERROR: {
      input = payload && payload.input;
      const message = payload && payload.error.message;
      return Object.assign({}, state, {
        [input]: message,
      });
    }
    // clear error information from corresponding input id
    case ERROR_CLEAR:
    case PAYMENT_VALIDATION_ERROR_CLEAR:
      return Object.assign({}, state, {
        [payload]: '',
      });
    // apply error to coupon form
    case ADD_COUPON_TO_CART_FAIL:
      return Object.assign({}, state, {
        // there is always only one error
        coupon: payload.error[0].message,
      });
    case VALIDATE_ACCESS_TOKEN_FAIL:
    case GET_PAYMENT_SOURCES_FAIL:
    case COMPLETE_CHECKOUT_FAIL:
    case CREATE_PAYMENT_METHOD_FAIL:
    case ADD_PRODUCT_TO_CART_FAIL: {
      input = 'root';
      // there is always only one error
      const message =
        payload.error && payload.error.message ? payload.error.message : payload.error[0] && payload.error[0].message;

      return Object.assign({}, state, {
        [input]: message || 'Errors.Unknown',
      });
    }
    // add special error message on special error case
    case ADD_PRODUCT_TO_CART_WRONG_COUPON:
      return Object.assign({}, state, {
        root: 'Errors.WrongCoupon',
      });
    // parse address form validation errors
    case SAVE_ADDRESS_FAIL: {
      // here could be multiple errors for form fields
      const errors = payload.error.reduce((acc, error) => {
        acc[error.field] = error.message;
        return acc;
      }, {});
      return Object.assign({}, state, errors);
    }
    case SAVE_PAYPAL_AGREEMENT_FAIL:
    case PAYPAL_CANCELED_AGREEMENT_SUCCESS:
    case PAYPAL_CANCELED_AGREEMENT_FAIL:
      return Object.assign({}, state, {
        root: 'Errors.PaymentFailure',
      });
    default:
      return state;
  }
}
