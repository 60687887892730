import ApiService from '../core/apiService';

const getSubscriptions = () => {
  const userId = ApiService.getUserId();
  if (!userId) {
    throw [new Error('common:Errors.Unauthorized')]; // eslint-disable-line
  }
  return ApiService.action('users_subscriptions', {
    method: 'get',
    urlParams: {
      userId,
    },
  });
};

/**
 * @function terminateUserSubscription
 * @param subscriptionId Id of a user subscription
 * @param terminate true if canceling immediately, false if it should be valid till the end of the period
 */
const terminateUserSubscription = (subscriptionId: number, terminate = false) => {
  const data = terminate ? { end_of_period: 0 } : undefined;
  const userId = ApiService.getUserId();
  if (!userId) {
    throw [new Error('common:Errors.Unauthorized')]; // eslint-disable-line
  }
  return ApiService.action('admin_web_subscriptions', {
    method: 'post',
    urlParams: {
      subscriptionId,
      userId,
    },
    data,
  });
};

const reactivateUserSubscription = (subscriptionId: number) => {
  const userId = ApiService.getUserId();
  if (!userId) {
    throw [new Error('common:Errors.Unauthorized')]; // eslint-disable-line
  }
  return ApiService.action('admin_web_subscriptions_restart', {
    method: 'put',
    urlParams: {
      subscriptionId,
      userId,
    },
  });
};

export function createSubscriptionService() {
  const service = {
    init: ApiService.init,
    getSubscriptions,
    terminateUserSubscription,
    reactivateUserSubscription,
  };
  return service;
}

const SubscriptionService = createSubscriptionService();
export default SubscriptionService;
