import { put, call, takeEvery, all } from 'redux-saga/effects';

import {
  GET_RELATED_PACKAGES_REQUEST,
  getRelatedPackagesSuccessAction,
  getRelatedPackagesFailAction,
} from '../actions';

import PackagesService from '../../../services/packagesService';

export function* handleRelatedPackagesRequest(action) {
  try {
    const productId = action.payload.productId;
    const response = yield call(PackagesService.getRelatedPackages, productId);
    yield put(getRelatedPackagesSuccessAction(productId, response.data.products));
  } catch (error) {
    yield put(getRelatedPackagesFailAction(error));
  }
}

export default function* watchPackages() {
  yield all([takeEvery(GET_RELATED_PACKAGES_REQUEST, handleRelatedPackagesRequest)]);
}
