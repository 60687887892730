// @flow
import type { AddressState } from '../state';
import type { Action } from '../actions/types';
import { GET_ADDRESSES_SUCCESS, SELECT_ADDRESS } from '../actions';

export const initState: AddressState = {
  addresses: [],
  selected: null,
};

export default function addressReducer(state?: AddressState = initState, action: Action): AddressState {
  const { type, payload } = action;
  switch (type) {
    case GET_ADDRESSES_SUCCESS: {
      const allAddresses =
        (payload && payload.response && payload.response.data && payload.response.data.addresses) || [];
      const lastUsedAddress = allAddresses.length > 0 ? allAddresses[0] : null;
      const addresses = lastUsedAddress ? [lastUsedAddress] : [];
      return Object.assign({}, state, {
        addresses,
        selected: lastUsedAddress,
      });
    }
    case SELECT_ADDRESS:
      return Object.assign({}, state, {
        selected: payload && payload.address,
      });
    default:
      return state;
  }
}
