import ApiService from '../../apiService';

const getUserRoles = () => {
  return ApiService.action('users', {
    method: 'get',
    params: {
      fields: 'roles',
    },
    urlParams: {
      userId: ApiService.getUserId(),
    },
  });
};

export function createAppService() {
  const service = {
    init: ApiService.init,
    getUserRoles,
  };

  return service;
}

const AppService = createAppService();
export default AppService;
