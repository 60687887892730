export type NotificationType = 'error' | 'success' | 'warning';

export interface INotification {
  id: number;
  type: NotificationType;
  message: string;
  autohide: boolean;
}

export enum NotificationTypes {
  error = 'error',
  success = 'success',
  warning = 'warning',
}

export class Notification implements INotification {
  id: number;
  type: NotificationType;
  message: string;
  autohide: boolean;
  constructor(type: NotificationType, message: string, autohide = true, id = Date.now()) {
    this.id = id;
    if (!message) {
      if (type === NotificationTypes.error) {
        message = 'Oops... something gone wrong';
      }
      if (type === NotificationTypes.success) {
        message = 'Successfully finished';
      }
    }
    this.message = message;
    this.type = NotificationTypes[type];
    this.autohide = autohide;
  }
}
