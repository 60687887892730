import { Action } from 'redux';
import { ITicketProps } from './types';

export const ADD_NEW_TICKET = 'ADD_NEW_TICKET';
export type ADD_NEW_TICKET_TYPE = typeof ADD_NEW_TICKET;
export interface IAddNewTicketAction extends Action<ADD_NEW_TICKET_TYPE> {
  type: ADD_NEW_TICKET_TYPE;
  ticket: ITicketProps;
}

export const ADD_NEW_TICKET_SUCCESS = 'ADD_NEW_TICKET_SUCCESS';
export type ADD_NEW_TICKET_SUCCESS_TYPE = typeof ADD_NEW_TICKET_SUCCESS;
export interface IAddNewTicketSuccessAction extends Action<ADD_NEW_TICKET_SUCCESS_TYPE> {
  type: ADD_NEW_TICKET_SUCCESS_TYPE;
}

export const ADD_NEW_TICKET_FAILURE = 'ADD_NEW_TICKET_FAILURE';
export type ADD_NEW_TICKET_FAILURE_TYPE = typeof ADD_NEW_TICKET_FAILURE;
export interface IAddNewTicketFailureAction extends Action<ADD_NEW_TICKET_FAILURE_TYPE> {
  type: ADD_NEW_TICKET_FAILURE_TYPE;
}

export const ADD_NEW_TICKET_RESET = 'ADD_NEW_TICKET_RESET';
export type ADD_NEW_TICKET_RESET_TYPE = typeof ADD_NEW_TICKET_RESET;
export interface IAddNewTicketResetAction extends Action<ADD_NEW_TICKET_RESET_TYPE> {
  type: ADD_NEW_TICKET_RESET_TYPE;
}

export type TTicketActions =
  | IAddNewTicketAction
  | IAddNewTicketSuccessAction
  | IAddNewTicketFailureAction
  | IAddNewTicketResetAction;
