export const I18NEXT_INIT = 'I18NEXT_INIT';
export const I18NEXT_READY = 'I18NEXT_READY';
export const I18NEXT_FAIL = 'I18NEXT_FAIL';

export const i18nextInitAction = (config) => {
  return {
    type: I18NEXT_INIT,
    payload: config,
  };
};

export const i18nextReadyAction = () => {
  return {
    type: I18NEXT_READY,
  };
};

export const i18nextFailAction = (error) => {
  return {
    type: I18NEXT_FAIL,
    payload: { error },
  };
};
