import { createStore, applyMiddleware, compose, Store, Action } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './redux/reducers';
import rootSaga from './redux/sagas';
import { i18nextInitAction } from './redux/actions';
import { IState } from './redux/state';

type TAppStore = Store<IState, Action>;
let store: TAppStore;

const getStore: () => TAppStore = () => {
  if (!!store) {
    return store;
  }

  const sagaMiddleware = createSagaMiddleware();
  //@ts-ignore
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(rootSaga, store.dispatch);

  store.dispatch(i18nextInitAction());
  return store;
};

export default getStore;
