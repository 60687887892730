// @flow
export const VALIDATE_ACCESS_TOKEN_REQUEST = 'VALIDATE_ACCESS_TOKEN_REQUEST';
export const ACCESS_TOKEN_FOUND = 'ACCESS_TOKEN_FOUND';
export const VALIDATE_ACCESS_TOKEN_SUCCESS = 'VALIDATE_ACCESS_TOKEN_SUCCESS';
export const VALIDATE_ACCESS_TOKEN_FAIL = 'VALIDATE_ACCESS_TOKEN_FAIL';

export const validateAccessTokenRequestAction = () => {
  return {
    type: VALIDATE_ACCESS_TOKEN_REQUEST,
  };
};

export const accessTokenFoundAction = (token: string) => {
  return {
    type: ACCESS_TOKEN_FOUND,
    payload: token,
  };
};

export const validateAccessTokenSuccessAction = (session: string) => {
  return {
    type: VALIDATE_ACCESS_TOKEN_SUCCESS,
    payload: session,
  };
};

export const validateAccessTokenFailAction = (error: any) => {
  return {
    type: VALIDATE_ACCESS_TOKEN_FAIL,
    payload: {
      error,
    },
  };
};
