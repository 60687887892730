declare global {
  interface Window {
    dataLayer: any[];
  }
}

window.dataLayer = window.dataLayer || [];
function gtag(...args: any) {
  window.dataLayer.push(arguments);
}

export function initConsent() {
  // set up default consent for GA4 (firebase)
  // this should work also for Universal Analytics (GA)
  gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
  });
  gtag('js', new Date());
}

export function updateConsent(granted: boolean) {
  // update consent according to the users consent
  gtag('consent', 'update', {
    ad_storage: granted ? 'granted' : 'denied',
    analytics_storage: granted ? 'granted' : 'denied',
  });
}

export function setUrlPassthrough() {
  gtag('set', 'url_passthrough', true);
}

export interface IGTagConfigOptions {
  // we are handling page view hits by ourselves
  send_page_view?: boolean;
  // optional Optimize plugin
  optimize_id?: string;
  // cookie expiration settings
  cookie_expires?: number;
  // update flag probably tells gtag to update the configuration
  // at least firebase seems to use it this way
  update?: boolean;
  // this way cookies are probably disabled for GA? TODO: check if it really works
  storage?: 'none';
  // this way advertisement related cookies are disabled
  storeGac?: boolean;
  // in case GDPR conserns arise
  anonymize_ip?: boolean;
  // client id saved via config call
  client_id?: string;
  // user id saved via config call
  user_id?: string;
}

export interface IGTagService {
  update: (options: IGTagConfigOptions) => void;
  event: (name: string, params?: any) => void;
  pageview: (location: string, path: string) => void;
  getClientId: () => Promise<string | undefined>;
  setClientId: (clientId: string) => void;
}

export function initPlugin(pluginId: string, options: IGTagConfigOptions) {
  // create functions for given pluginId
  const update = configurePlugin(pluginId);
  const event = sendEvent(pluginId);
  const pageview = (location: string, path: string) => {
    const pageViewParams = {
      page_path: path,
      page_location: location,
    };
    event('page_view', pageViewParams);
  };
  const getClientId = getClientIdFromPlugin(pluginId);
  const setClientId = setClientIdToPlugin(pluginId);
  // create instance
  const instance: IGTagService = {
    update,
    event,
    pageview,
    getClientId,
    setClientId,
  };
  // create plugin in gtag
  update(options);
  return instance;
}

const configurePlugin = (pluginId: string) => (options: IGTagConfigOptions) => {
  gtag('config', pluginId, options);
};

const sendEvent = (pluginId: string) => (name: string, params: any = {}) => {
  const customParams = {
    send_to: pluginId,
    ...params,
  };
  gtag('event', name, customParams);
};

const getClientIdFromPlugin = (pluginId: string) => () => {
  return new Promise<string | undefined>((resolve) => {
    gtag('get', pluginId, 'client_id', (clientId?: string) => {
      resolve(clientId);
    });
  });
};

export const setClientIdToPlugin = (pluginId: string) => (clientId: string) => {
  const options: IGTagConfigOptions = {
    client_id: clientId,
  };
  gtag('config', pluginId, options);
};
