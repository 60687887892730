//@flow
export const GET_SUBSCRIPTIONS_REQUEST = 'GET_SUBSCRIPTIONS_REQUEST';
export const getSubscriptionsRequestAction = () => {
  return {
    type: GET_SUBSCRIPTIONS_REQUEST,
  };
};

export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const getSubscriptionsSuccessAction = (response: any) => {
  return {
    type: GET_SUBSCRIPTIONS_SUCCESS,
    payload: {
      response,
    },
  };
};

export const GET_SUBSCRIPTIONS_FAILED = 'GET_SUBSCRIPTIONS_FAILED';
export const getSubscriptionsFailedAction = (error: any) => {
  return {
    type: GET_SUBSCRIPTIONS_FAILED,
    payload: { error },
  };
};

export const TERMINATE_SUBSCRIPTION_REQUEST = 'TERMINATE_SUBSCRIPTION_REQUEST';
export const terminateSubscriptionRequestAction = (subscriptionId, terminate = false) => {
  return {
    type: TERMINATE_SUBSCRIPTION_REQUEST,
    payload: {
      subscriptionId,
      terminate,
    },
  };
};

export const TERMINATE_SUBSCRIPTION_SUCCESS = 'TERMINATE_SUBSCRIPTION_SUCCESS';
export const terminateSubscriptionSuccessAction = (response: any) => {
  return {
    type: TERMINATE_SUBSCRIPTION_SUCCESS,
    payload: {
      response,
    },
  };
};

export const TERMINATE_SUBSCRIPTION_FAILED = 'TERMINATE_SUBSCRIPTION_FAILED';
export const terminateSubscriptionFailedAction = (error: any) => {
  return {
    type: TERMINATE_SUBSCRIPTION_FAILED,
    payload: { error },
  };
};

export const RESTORE_SUBSCRIPTION_REQUEST = 'RESTORE_SUBSCRIPTION_REQUEST';
export const restoreSubscriptionRequestAction = (subscriptionId) => {
  return {
    type: RESTORE_SUBSCRIPTION_REQUEST,
    payload: {
      subscriptionId,
    },
  };
};

export const RESTORE_SUBSCRIPTION_SUCCESS = 'RESTORE_SUBSCRIPTION_SUCCESS';
export const restoreSubscriptionSuccessAction = () => {
  return {
    type: RESTORE_SUBSCRIPTION_SUCCESS,
  };
};

export const RESTORE_SUBSCRIPTION_FAILED = 'RESTORE_SUBSCRIPTION_FAILED';
export const restoreSubscriptionFailedAction = (error: any) => {
  return {
    type: RESTORE_SUBSCRIPTION_FAILED,
    payload: { error },
  };
};

export const RESTORE_SUBSCRIPTION_CONFIRM = 'RESTORE_SUBSCRIPTION_CONFIRM';
export const confirmRestoreSubscriptionAction = () => {
  return {
    type: RESTORE_SUBSCRIPTION_CONFIRM,
  };
};
