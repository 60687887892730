import React, { FC } from 'react';
import i18next from 'i18next';

import OrderItem from './OrderItem';
import { IOrder } from '../types';

export interface IOrdersListProps {
  orders: IOrder[];
}

const OrdersList: FC<IOrdersListProps> = ({ orders }) => {
  const renderOrders = () => {
    if (orders && orders.length > 0) {
      return orders.map((order) => <OrderItem key={order.referenceId} order={order} />);
    }
    return (
      <tr>
        <td colSpan={4}>{i18next.t('checkout:Orders.Notifications.noData')}</td>
      </tr>
    );
  };

  return (
    <div className="table-responsive">
      <table className="table table-sm table-borderless table-hover">
        <thead>
          <tr>
            <th>{i18next.t('checkout:Orders.number')}</th>
            <th>{i18next.t('checkout:Orders.date')}</th>
            <th>{i18next.t('checkout:Orders.total')}</th>
            <th>{i18next.t('common:Download')}</th>
          </tr>
        </thead>
        <tbody>{renderOrders()}</tbody>
      </table>
    </div>
  );
};

export default OrdersList;
