import * as ActionTypes from './actionTypes';
import { IRemoteConfig } from './config';

export interface IFirebaseState {
  // config loaded from firebase remote config
  config?: IRemoteConfig;
  // feature ready status
  ready: boolean;
}

export const initialState: IFirebaseState = {
  ready: false,
};

export const statePropName = 'firebase';

export default function firebaseReducer(
  state: IFirebaseState = initialState,
  action: ActionTypes.IFirebaseActions
): IFirebaseState {
  switch (action.type) {
    case ActionTypes.FIREBASE_READY: {
      return {
        ...state,
        ready: true,
      };
    }
    case ActionTypes.UPDATE_REMOTE_CONFIG: {
      const config = action.config;
      return {
        ...state,
        config,
      };
    }
  }
  return state;
}
