import ApiService from '../core/apiService';

export function getTaboolaService() {
  let clickId: string | undefined;
  const init = (taboolaClickId?: string) => {
    clickId = taboolaClickId;
  };
  const trackPurchase = (revenue: number, currency: string) => {
    if (!clickId) {
      // do not track if click id not set
      return;
    }
    return ApiService.action('analytics', {
      method: 'get',
      urlParams: {
        service: 't',
      },
      params: {
        'click-id': clickId,
        name: 'purchase',
        revenue,
        currency,
      },
    });
  };
  return {
    init,
    trackPurchase,
  };
}

const taboolaService = getTaboolaService();
export default taboolaService;
