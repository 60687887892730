import ApiService from '../core/apiService';
import { IOrdersRequestPayload } from './types';

const getOrders = () => {
  const userId = ApiService.getUserId();
  if (!userId) {
    throw [new Error('common:Errors.Unauthorized')]; // eslint-disable-line
  }
  return ApiService.action<IOrdersRequestPayload>('users_orders', {
    method: 'get',
    urlParams: {
      userId,
    },
  });
};

export function createOrdersService() {
  const service = {
    init: ApiService.init,
    getOrders,
  };
  return service;
}

const OrdersService = createOrdersService();
export default OrdersService;
