import ApiService from '../core/apiService';

const getRelatedPackages = (productId: number) => {
  return ApiService.action('web_products_filtering', {
    method: 'get',
    params: {
      productId,
      type: 'group',
    },
  });
};

export function createPackagesService() {
  const service = {
    init: ApiService.init,
    getRelatedPackages,
  };
  return service;
}

const PackagesService = createPackagesService();
export default PackagesService;
