import * as Reducer from './reducer';

export interface IAppState {
  // this feature state
  [Reducer.statePropName]: Reducer.IGdprState;
  // other features we don't care of
  [otherKeys: string]: any;
}

const getState = (state: IAppState) => state[Reducer.statePropName];

export const getFirebaseConsent = (state: IAppState) => getState(state).firebase;

export const getOptimizeConsent = (state: IAppState) => !!getState(state).optimize;

export const isConsentGiven = (state: IAppState) => getState(state).submitted;

export const isSettingsOpen = (state: IAppState) => getState(state).settings;

export const getBannerMode = (state: IAppState) => getState(state).mode;
