import { Dispatch } from 'redux';
import { all, put, takeEvery, call } from 'redux-saga/effects';
import getStore from '../core';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import service from './service';

export function* handleFeatureStart() {
  try {
    const dispatch: Dispatch = (action) => {
      const store = getStore();
      store.dispatch(action);
      return action;
    };
    yield call(service.init, dispatch);
  } catch (error) {
    yield put(Actions.languageFailAction(error));
  }
}

export function* changeLanguageSaga({ language }: ActionTypes.IChangeLanguageAction) {
  try {
    yield call(service.changeLanguage, language);
  } catch (error) {
    yield put(Actions.languageFailAction(error));
  }
}

export default function* languageSagaWatcher() {
  yield all([
    takeEvery(ActionTypes.LANGUAGE_INIT, handleFeatureStart),
    takeEvery(ActionTypes.LANGUAGE_CHANGE, changeLanguageSaga),
  ]);
}
