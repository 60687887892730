import { all, call, put, spawn } from 'redux-saga/effects';
import { FirebaseSaga as watchFirebase } from '../../../firebase';
import { GdprSaga as watchGdpr } from '../../../gdpr';
import watchValidation from './validationSaga';
import watchPayment from './paymentSaga';
import watchCart from './cartSaga';
import watchI18Next from './i18nextSaga';
import watchApp from './appSaga';
import watchAddress from './addressSaga';
import watchPackages from './packagesSaga';
import { checkoutFailAction } from '../actions';
import watchCheckout from './checkoutSaga';
import watchSubscription from './subscriptionSaga';
import { OrdersSaga } from '../../../orders';
import { LanguageSaga } from '../../../language';
import { TicketSaga } from '../../../ticket';
import { Lottie } from 'web_opxp_components';

export default function* rootSaga(dispatch) {
  const sagas = [
    watchApp,
    watchValidation,
    watchPayment,
    watchCart,
    watchI18Next,
    watchAddress,
    watchPackages,
    watchCheckout,
    watchSubscription,
    OrdersSaga,
    watchFirebase,
    watchGdpr,
    LanguageSaga,
    Lottie.Saga,
    TicketSaga,
  ];
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga, dispatch);
          } catch (error) {
            yield put(checkoutFailAction(error.message));
          }
        }
      })
    )
  );
}
