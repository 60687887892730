export type Callback = () => void;

export type DataCallback = (...args: any[]) => void;

export interface ClickEvent {
  target: string;
  preventDefault: Callback;
  stopPropagation: Callback;
}

export interface CardAction {
  label: string;
  class?: string;
  action: Callback;
}

export enum CheckoutCompletedViewEnum {
  CrossPromotion = 'cross_promotion',
  CompletedURL = 'completed_url',
  TrainingAssistant = 'training_assistant',
  BankTransfer = 'bank_transfer',
  EasePromotion = 'ease_promotion',
}
