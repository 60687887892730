import { put, call, takeEvery, takeLeading, all, delay } from 'redux-saga/effects';
import i18next from 'i18next';
import { Notification, NotificationTypes } from '../../types/notification';

import { GET_SUBSCRIPTIONS_REQUEST, RESTORE_SUBSCRIPTION_REQUEST, TERMINATE_SUBSCRIPTION_REQUEST } from '../actions';

import {
  getSubscriptionsSuccessAction,
  getSubscriptionsFailedAction,
  terminateSubscriptionSuccessAction,
  terminateSubscriptionFailedAction,
  restoreSubscriptionFailedAction,
  restoreSubscriptionSuccessAction,
  getSubscriptionsRequestAction,
  addNotificationAction,
} from '../actions';

import SubscriptionService from '../../../services/subscriptionService';

export function* handleGetSubscriptions() {
  try {
    const result = yield call(SubscriptionService.getSubscriptions);
    yield put(getSubscriptionsSuccessAction(result));
  } catch (errors) {
    console.log('handleGetSubscriptions', errors);
    yield all(
      errors.map((error) => {
        const message = i18next.t(error.message);
        const notification = new Notification(NotificationTypes.error, message, false);
        return put(addNotificationAction(notification));
      })
    );
    yield put(getSubscriptionsFailedAction(errors));
  }
}

export function* handleTerminateSubscription(action) {
  try {
    yield call(SubscriptionService.terminateUserSubscription, action.payload.subscriptionId);
    // wait until backend process the data
    yield delay(300);
    yield put(terminateSubscriptionSuccessAction());
    const notification = new Notification(
      NotificationTypes.success,
      i18next.t('checkout:Subscriptions.Notifications.terminationSuccess')
    );
    yield put(addNotificationAction(notification));
    yield put(getSubscriptionsRequestAction());
  } catch (errors) {
    yield all(
      errors.map((error) => {
        const message = i18next.t(error.message);
        const notification = new Notification(NotificationTypes.error, message, false);
        return put(addNotificationAction(notification));
      })
    );
    yield put(terminateSubscriptionFailedAction(errors));
  }
}

export function* handleRestoreSubscription(action) {
  try {
    yield call(SubscriptionService.reactivateUserSubscription, action.payload.subscriptionId);
    yield delay(300);
    yield put(restoreSubscriptionSuccessAction());
    yield put(getSubscriptionsRequestAction());
  } catch (errors) {
    yield all(
      errors.map((error) => {
        const message = i18next.t(error.message);
        const notification = new Notification(NotificationTypes.error, message, false);
        return put(addNotificationAction(notification));
      })
    );
    yield put(restoreSubscriptionFailedAction(errors));
  }
}

export default function* watchSubscription(): Generator<any, any, any> {
  yield all([
    takeLeading(GET_SUBSCRIPTIONS_REQUEST, handleGetSubscriptions),
    takeEvery(TERMINATE_SUBSCRIPTION_REQUEST, handleTerminateSubscription),
    takeLeading(RESTORE_SUBSCRIPTION_REQUEST, handleRestoreSubscription),
  ]);
}
