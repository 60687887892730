import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';

import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import { getDefaultRestoreSubscriptionProducts, IRemoteConfig } from './config';
import * as GA from './ga';
import * as GTAG from './gtag';
import outbrainService from './outbrainService';
import * as Selectors from './selectors';
import firebase from './service';
import taboolaService from './taboolaService';
import { prefixGAEventName } from './utils';

// initialize analytics if not yet done
export function* initTrackingFeatureSaga({ sessionId }: ActionTypes.IFirebaseInitAction) {
  // init consent feature
  yield call(GTAG.initConsent);
  // set url passthrough
  yield call(GTAG.setUrlPassthrough);
  // configure GA for the first time
  yield call(GA.initGA, sessionId);
  // configure firebase
  yield call(setUpFirebase, sessionId);
}

export function* setUpFirebase(sessionId?: string) {
  let restoreSubscriptionProductsString: string | undefined;
  try {
    // Hide error message if browser doesn't support
    yield call(firebase.init, sessionId);
    yield call(firebase.initializeConfig);
    restoreSubscriptionProductsString = yield call(firebase.getRestoreSubscriptionProductsConfig);
  } catch (error) {
    console.log('Firebase initialization error:', error);
  }
  const config: IRemoteConfig = {
    restoreProducts:
      typeof restoreSubscriptionProductsString !== 'undefined'
        ? restoreSubscriptionProductsString
        : getDefaultRestoreSubscriptionProducts(),
  };
  yield put(Actions.updateRemoteConfigAction(config));
  yield put(Actions.firebaseReadyAction());
}

export function* waitToBeReady() {
  const ready: boolean = yield select(Selectors.isReady);
  if (!ready) {
    yield take(ActionTypes.FIREBASE_READY);
  }
}

// set up tracking with given consent
export function* configureTracking({ analytics, optimize }: ActionTypes.IFirebaseStartAction) {
  yield call(waitToBeReady);
  // enable tracking if consent is given
  yield call(GTAG.updateConsent, analytics);
  // set related user property
  yield call(firebase.setCookieConsentProperty, analytics);
  // change GA config to include optimize
  if (!!analytics && !!optimize) {
    yield call(GA.updateGAWithOptimize);
  }
}

export function* disableCookiesSaga() {
  yield call(waitToBeReady);
  // disable tracking
  yield call(GTAG.updateConsent, false);
  // set related user property
  yield call(firebase.setCookieConsentProperty, false);
}

// fire custom event
export function* sendAnalyticsEvent({ analyticsEvent }: ActionTypes.IAnalyticsEventAction) {
  yield call(waitToBeReady);
  yield call(firebase.analyticsEvent, analyticsEvent);
}

// fire event to custom GA instance
export function* sendGAEvent(action: ActionTypes.IGAEventAction) {
  const { eventName, parameters } = action.analyticsEvent;
  yield call(GA.event, prefixGAEventName(eventName), parameters);
}

export function* initTaboolaServiceSaga({ taboolaClickId }: ActionTypes.IInitTaboolaAction) {
  yield call(taboolaService.init, taboolaClickId);
}

export function* trackPurchaseOnTaboolaSaga({ revenue, currency }: ActionTypes.ITaboolaPurchaseEventAction) {
  try {
    yield call(taboolaService.trackPurchase, revenue, currency);
  } catch (error) {
    console.log(error);
  }
}

export function* initOutbrainServiceSaga({ outbrainClickId }: ActionTypes.IInitOutbrainAction) {
  // configure outbrain service
  yield call(outbrainService.init, outbrainClickId);
}

export function* trackPurchaseOnOutbrainSaga({ revenue, currency }: ActionTypes.IOutbrainPurchaseEventAction) {
  try {
    yield call(outbrainService.trackPurchase, revenue, currency);
  } catch (error) {
    console.log(error);
  }
}

export function* setUserIdSaga({ userId }: ActionTypes.ISetUserIdAction) {
  // wait for firebase to be ready before setting userid
  yield call(waitToBeReady);
  // set user id in GA
  yield call(GA.setUserIdForGA, userId);
  // set user id in Firebase
  yield call(firebase.setUserId, userId);
}

// base watcher for Firebase actions
export default function* watchFirebase() {
  yield all([
    takeEvery(ActionTypes.FIREBASE_INIT, initTrackingFeatureSaga),
    takeEvery(ActionTypes.FIREBASE_START, configureTracking),
    takeEvery(ActionTypes.ANALYTICS_EVENT, sendAnalyticsEvent),
    takeEvery(ActionTypes.GA_EVENT, sendGAEvent),
    takeEvery(ActionTypes.FIREBASE_DISABLE, disableCookiesSaga),
    takeEvery(ActionTypes.TABOOLA_INIT, initTaboolaServiceSaga),
    takeEvery(ActionTypes.TABOOLA_PURCHASE_EVENT, trackPurchaseOnTaboolaSaga),
    takeEvery(ActionTypes.OUTBRAIN_INIT, initOutbrainServiceSaga),
    takeEvery(ActionTypes.OUTBRAIN_PURCHASE_EVENT, trackPurchaseOnOutbrainSaga),
    takeEvery(ActionTypes.SET_USERID, setUserIdSaga),
  ]);
}
