import { all, put, takeEvery, call } from 'redux-saga/effects';

import { addNewTicketFailureAction, addNewTicketSuccessAction } from './actions';
import { ADD_NEW_TICKET, IAddNewTicketAction } from './actionTypes';
import { getSessionToken } from '../helpers/parameters';
import service from './service';

export function* handleSendTicket(action: IAddNewTicketAction) {
  try {
    const token = getSessionToken() || '';
    yield call([service, 'init'], token);
    // send ticket (email) to CC
    yield call([service, 'sendTicket'], action.ticket);
    yield put(addNewTicketSuccessAction());
  } catch {
    yield put(addNewTicketFailureAction());
  }
}

export default function* ticketSagaWatcher(): Generator {
  yield all([takeEvery(ADD_NEW_TICKET, handleSendTicket)]);
}
