export type TProductType = 'NN' | 'EASE' | 'NN_AND_EASE';

export interface ISubscription {
  autoRenewing: boolean;
  createdAt: string;
  expiresAt: string;
  id: number;
  isLegacy: boolean;
  isTerminable: boolean;
  nextCharge: string;
  numberIntervals: number;
  productId: number;
  project: TProductType;
  trialEndsAt: unknown | null;
  type: string;
}

export enum ESubscriptionRestoreState {
  None,
  Running,
  Success,
}
