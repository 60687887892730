import {
  ADD_NEW_TICKET,
  ADD_NEW_TICKET_FAILURE,
  ADD_NEW_TICKET_RESET,
  ADD_NEW_TICKET_SUCCESS,
  IAddNewTicketAction,
  IAddNewTicketFailureAction,
  IAddNewTicketResetAction,
  IAddNewTicketSuccessAction,
} from './actionTypes';
import type { ITicketProps } from './types';

export const addNewTicketAction = (ticket: ITicketProps): IAddNewTicketAction => {
  return {
    type: ADD_NEW_TICKET,
    ticket,
  };
};

export const addNewTicketSuccessAction = (): IAddNewTicketSuccessAction => {
  return {
    type: ADD_NEW_TICKET_SUCCESS,
  };
};

export const addNewTicketFailureAction = (): IAddNewTicketFailureAction => {
  return {
    type: ADD_NEW_TICKET_FAILURE,
  };
};

export const addNewTicketResetAction = (): IAddNewTicketResetAction => {
  return {
    type: ADD_NEW_TICKET_RESET,
  };
};
