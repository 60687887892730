import takeRight from 'lodash/takeRight';
import UrlParser from '../helpers/urlParser';
import { ICookieConsent } from './types';

function getCookie(cName: string) {
  const name = cName + '=';
  // get the full cookies string
  const decodedCookie = decodeURIComponent(document.cookie);
  // split into single cookies strings
  const cookies = decodedCookie.split(';');
  // search for cookie with given name
  for (let i = 0; i < cookies.length; i += 1) {
    let cookie = cookies[i];
    // trim whitespaces from start
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    // detect if cookie starts with requested name
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
}

function isLocal() {
  const domain = window.location.hostname;
  return /localhost/i.test(domain);
}

function isProduction() {
  const domain = window.location.hostname;
  const isLocalhost = isLocal();
  const isHeroku = /heroku/i.test(domain);
  return !isLocalhost && !isHeroku;
}

function getDomain() {
  // get domain from current url
  const domain = window.location.hostname;
  if (!isProduction()) {
    return domain;
  }
  // split in parts
  const parts = domain.split('.');
  // take last two domains
  const lastParts = takeRight(parts, 2);
  // compile back to string
  return lastParts.join('.');
}

export const COOKIE_DURATION_MS = 365 * 24 * 60 * 60 * 1000;

function setCookie(cName: string, cValue: string, disableSameSite: boolean = false) {
  const cookieValue = encodeURIComponent(cValue);
  const attributes: string[] = [];
  const expirationDate = new Date(Date.now() + COOKIE_DURATION_MS);
  attributes.push('expires=' + expirationDate.toUTCString());
  attributes.push('domain=' + getDomain());
  attributes.push('path=/');
  if (!isLocal()) {
    attributes.push('Secure');
    if (!disableSameSite) {
      attributes.push('SameSite=Strict');
    }
  }
  if (disableSameSite) {
    attributes.push('SameSite=None');
  }
  const newCookie = `${cName}=${cookieValue}; ` + attributes.join('; ');
  document.cookie = newCookie;
  return newCookie;
}

export const COOKIE_CONSENT_NAME = 'nn_consent';

export function getConsent() {
  const consentCookie = getCookie(COOKIE_CONSENT_NAME);
  if (!consentCookie) {
    return;
  }
  try {
    return JSON.parse(consentCookie) as ICookieConsent;
  } catch (error) {
    return;
  }
}

export function saveConsent(consent: ICookieConsent) {
  const consentValue = JSON.stringify(consent);
  return setCookie(COOKIE_CONSENT_NAME, consentValue);
}

export const getCks = () => {
  const search = UrlParser.getCurrentUrlSearch();
  return UrlParser.getUrlSearchValue(search, 'cks');
};

export const getSessionId = () => {
  const search = UrlParser.getCurrentUrlSearch();
  const sessionId = UrlParser.getUrlSearchValue(search, 'sessionId');
  if (!sessionId) {
    return;
  }
  return sessionId;
};
