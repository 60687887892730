import * as Reducer from './reducer';

export interface IAppState {
  // this feature state
  [Reducer.statePropName]: Reducer.ITicketState;
  // other features we don't care of
  [otherKeys: string]: any;
}

const getAppState = (state: IAppState) => state.ticket;

export function isTicketSent(state: IAppState) {
  return getAppState(state).isSent;
}
