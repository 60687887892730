import { Action } from 'redux';

export const LANGUAGE_INIT = 'LANGUAGE_INIT';
export type LANGUAGE_INIT_TYPE = typeof LANGUAGE_INIT;
export interface ILanguageInitAction extends Action<LANGUAGE_INIT_TYPE> {
  type: LANGUAGE_INIT_TYPE;
}

export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';
export type LANGUAGE_CHANGE_TYPE = typeof LANGUAGE_CHANGE;
export interface IChangeLanguageAction extends Action<LANGUAGE_CHANGE_TYPE> {
  type: LANGUAGE_CHANGE_TYPE;
  language: string;
}

export const LANGUAGE_UPDATE = 'LANGUAGE_UPDATE';
export type LANGUAGE_UPDATE_TYPE = typeof LANGUAGE_UPDATE;
export interface IUpdateLanguageAction extends Action<LANGUAGE_UPDATE_TYPE> {
  type: LANGUAGE_UPDATE_TYPE;
  language?: string;
}

export const LANGUAGE_FAIL = 'LANGUAGE_FAIL';
export type LANGUAGE_FAIL_TYPE = typeof LANGUAGE_FAIL;
export interface ILanguageFailAction extends Action<LANGUAGE_FAIL_TYPE> {
  type: LANGUAGE_FAIL_TYPE;
  error: Error;
}

export type TLanguageActions =
  | ILanguageInitAction
  | IChangeLanguageAction
  | IUpdateLanguageAction
  | ILanguageFailAction;
