import { cps, put, takeEvery, all } from 'redux-saga/effects';
import { I18NEXT_INIT, i18nextReadyAction, i18nextFailAction } from '../actions';
import initI18n from '../../../i18next';

export function* i18nextInitSaga(action) {
  try {
    yield cps(initI18n, action.payload);
    yield put(i18nextReadyAction());
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(i18nextFailAction(error));
  }
}

export default function* watchI18Next() {
  yield all([takeEvery(I18NEXT_INIT, i18nextInitSaga)]);
}
