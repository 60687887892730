//@flow
import type { Action, SuccessResponseAction } from './types';

export const GET_ADDRESSES_REQUEST = 'GET_ADDRESSES_REQUEST';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_FAIL = 'GET_ADDRESSES_FAIL';
export const SAVE_ADDRESS_REQUEST = 'SAVE_ADDRESS_REQUEST';
export const SAVE_ADDRESS_SUCCESS = 'SAVE_ADDRESS_SUCCESS';
export const SAVE_ADDRESS_FAIL = 'SAVE_ADDRESS_FAIL';
export const SELECT_ADDRESS = 'SELECT_ADDRESS';

export const getAddressesRequestAction: () => Action = () => {
  return {
    type: GET_ADDRESSES_REQUEST,
  };
};

export const getAddressesSuccessAction: (any) => SuccessResponseAction = (response) => {
  return {
    type: GET_ADDRESSES_SUCCESS,
    payload: {
      response,
    },
  };
};

export const getAddressesFailAction: (any) => Action = (error) => {
  return {
    type: GET_ADDRESSES_FAIL,
    payload: {
      error,
    },
  };
};

export const saveAddressRequestAction: (any) => Action = (address) => {
  return {
    type: SAVE_ADDRESS_REQUEST,
    payload: {
      address,
    },
  };
};

export const saveAddressSuccessAction: (any) => SuccessResponseAction = (response) => {
  return {
    type: SAVE_ADDRESS_SUCCESS,
    payload: {
      response,
    },
  };
};

export const saveAddressFailAction: (any) => Action = (error) => {
  return {
    type: SAVE_ADDRESS_FAIL,
    payload: {
      error,
    },
  };
};

export const selectAddressAction: (any) => Action = (address) => {
  return {
    type: SELECT_ADDRESS,
    payload: {
      address,
    },
  };
};
