import type { IState } from '../state';

function getAppState(state: IState) {
  return state.app;
}

export function getBackUrl(state: IState) {
  return getAppState(state).backUrl;
}

export function getCompleteUrl(state: IState) {
  return getAppState(state).completeUrl;
}

export function getTouModalState(state: IState) {
  return getAppState(state).showTou;
}

export function getLoading(state: IState) {
  return getAppState(state).loading;
}

export function getSessionToken(state: IState) {
  return getAppState(state).session;
}

export function getUserData(state: IState) {
  return getAppState(state).user;
}

export function getUserIsAuthorized(state: IState) {
  return !!getUserData(state)?.isAuthorized;
}

export function getUserMail(state: IState) {
  return getUserData(state)?.mail;
}

export function getUserName(state: IState) {
  return getUserData(state)?.name;
}

export function getUserIsPremium(state: IState) {
  return !!getUserData(state)?.isPremium;
}

export function getUserPicture(state: IState) {
  return getUserData(state)?.picture;
}
