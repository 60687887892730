import React, { FC } from 'react';
import i18next from 'i18next';

import { formatDate } from '../../helpers/formatters';
import { IOrder } from '../types';

export interface IOrderItemProps {
  order: IOrder;
}

const OrderItem: FC<IOrderItemProps> = ({ order }) => {
  const { referenceId, date, total, invoiceUrl } = order;
  const invoiceDate = formatDate(date);

  return (
    <tr>
      <td>{referenceId}</td>
      <td>{invoiceDate}</td>
      <td>
        {total.amount.toFixed(2)} {total.currency}
      </td>
      <td>
        <a href={invoiceUrl} target="_blank" rel="noreferrer">
          {i18next.t('checkout:Orders.format')}
        </a>
      </td>
    </tr>
  );
};

export default OrderItem;
