import { ECartItemType } from '../core/types/cart';

export function isSubscription(cartItemType: ECartItemType) {
  return cartItemType === ECartItemType.GROUP_SUBSCRIPTION || cartItemType === ECartItemType.SUBSCRIPTION;
}

export function isGroupPackage(cartItemType: ECartItemType) {
  return cartItemType === ECartItemType.GROUP_SUBSCRIPTION || cartItemType === ECartItemType.GROUP_ONE_TIME;
}

export function isOneTime(cartItemType: ECartItemType) {
  return cartItemType === ECartItemType.ONE_TIME || cartItemType === ECartItemType.GROUP_ONE_TIME;
}
