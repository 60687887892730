// @flow
import type { CheckoutState } from '../state';
import type { Action } from '../actions';

import {
  GET_PAYMENT_SOURCES_REQUEST,
  GET_PAYMENT_SOURCES_SUCCESS,
  GET_PAYMENT_SOURCES_FAIL,
  COMPLETE_CHECKOUT,
  COMPLETE_CHECKOUT_SUCCESS,
  COMPLETE_CHECKOUT_FAIL,
  CHECKOUT_FINISHED,
  GET_ADDRESSES_REQUEST,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESSES_FAIL,
  SAVE_ADDRESS_REQUEST,
  SAVE_ADDRESS_SUCCESS,
  SAVE_ADDRESS_FAIL,
  GET_RELATED_PACKAGES_REQUEST,
  GET_RELATED_PACKAGES_SUCCESS,
  GET_RELATED_PACKAGES_FAIL,
  CHECKOUT_START,
  CHECKOUT_STARTED,
  CHECKOUT_FAIL,
  PAYPAL_SUCCESSFUL_AGREEMENT_METHOD,
  SAVE_PAYPAL_AGREEMENT_FAIL,
  PAYPAL_CANCELED_AGREEMENT_METHOD,
  PAYPAL_CANCELED_AGREEMENT_SUCCESS,
  PAYPAL_CANCELED_AGREEMENT_FAIL,
  COMPLETE_CHECKOUT_VIEW,
  ADD_TRAINING_ASSISTANT,
  ADD_TRAINING_ASSISTANT_SUCCESS,
  CREATE_PAYMENT_METHOD_FAIL,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_REQUEST_METHOD,
  CHECKOUT_SUBMITTED,
  ADD_PRODUCT_TO_CART_FAIL,
} from '../actions';

export const initState: CheckoutState = {
  loading: 0,
  completedView: null,
  submitted: false,
};

export default function checkoutReducer(state?: CheckoutState = initState, action: Action): CheckoutState {
  const { type, payload } = action;
  switch (type) {
    case CHECKOUT_START:
    case GET_PAYMENT_SOURCES_REQUEST:
    case GET_ADDRESSES_REQUEST:
    case SAVE_ADDRESS_REQUEST:
    case GET_RELATED_PACKAGES_REQUEST:
    case PAYPAL_SUCCESSFUL_AGREEMENT_METHOD:
    case PAYPAL_CANCELED_AGREEMENT_METHOD:
    case ADD_TRAINING_ASSISTANT:
    case CREATE_PAYMENT_REQUEST_METHOD:
      return Object.assign({}, state, {
        loading: state.loading + 1,
      });
    case COMPLETE_CHECKOUT: {
      const submitted = state.submitted;
      const loading = state.loading + (submitted ? 0 : 1);
      return Object.assign({}, state, {
        loading,
      });
    }
    case CHECKOUT_STARTED:
    case GET_PAYMENT_SOURCES_SUCCESS:
    case COMPLETE_CHECKOUT_SUCCESS:
    case GET_ADDRESSES_SUCCESS:
    case SAVE_ADDRESS_SUCCESS:
    case GET_RELATED_PACKAGES_SUCCESS:
    case CHECKOUT_FAIL:
    case GET_PAYMENT_SOURCES_FAIL:
    case GET_ADDRESSES_FAIL:
    case SAVE_ADDRESS_FAIL:
    case GET_RELATED_PACKAGES_FAIL:
    case CREATE_PAYMENT_METHOD_FAIL:
    case CREATE_PAYMENT_METHOD_SUCCESS:
    case SAVE_PAYPAL_AGREEMENT_FAIL:
    case PAYPAL_CANCELED_AGREEMENT_SUCCESS:
    case PAYPAL_CANCELED_AGREEMENT_FAIL:
    case ADD_TRAINING_ASSISTANT_SUCCESS:
    case CHECKOUT_FINISHED:
    case ADD_PRODUCT_TO_CART_FAIL:
      return Object.assign({}, state, {
        loading: state.loading - 1,
      });
    case COMPLETE_CHECKOUT_VIEW:
      return Object.assign({}, state, {
        completedView: payload.completedView,
      });
    case CHECKOUT_SUBMITTED:
      return Object.assign({}, state, {
        submitted: true,
      });
    case COMPLETE_CHECKOUT_FAIL:
      return Object.assign({}, state, {
        loading: state.loading - 1,
        submitted: false,
      });
    default:
      return state;
  }
}
