import { put, call, takeEvery, all } from 'redux-saga/effects';
import ValidationService from '../../../services/validationService';
import sStorage from '../../../helpers/sessionStorage';
import { retrieveParameter, UrlParams, getSessionToken, setSessionToken } from '../../../helpers/parameters';

import {
  VALIDATE_ACCESS_TOKEN_REQUEST,
  validateAccessTokenSuccessAction,
  validateAccessTokenFailAction,
  accessTokenFoundAction,
} from '../actions';
import { FirebaseActions } from '../../../firebase';

export function* handleValidateAccessTokenRequest() {
  try {
    let token = getSessionToken();
    if (!token) {
      // after transition to SSO this should be removed
      token = retrieveParameter(UrlParams.AccessToken);
    }
    // throw error if no token found
    if (!token) {
      throw [{ message: 'Errors.Unauthorized' }]; // eslint-disable-line
    }
    yield put(accessTokenFoundAction(token));
    // parse JWT token
    const headerData = JSON.parse(atob(token.split('.')[0]));
    const sessionData = JSON.parse(atob(token.split('.')[1]));
    // access token has a unique property 'device'
    // migrated legacy users have old access token with HS256 as algorythm
    const isAccessToken = !!sessionData.device || headerData.alg === 'HS256';
    // access token is still used by legacy system (legacy.neuronation.com) - can not be removed until legacy is turned off
    if (isAccessToken) {
      // TODO: this is deprecated and should be removed after SSO support implementation
      const response = yield call(ValidationService.validateAccessToken, token);
      const { userId, sessionToken } = response.data.result;
      yield put(FirebaseActions.setUserIdAction(userId));
      sStorage.setUserId(userId);
      setSessionToken(sessionToken);
      yield put(validateAccessTokenSuccessAction(sessionToken));
      return;
    }
    const userId = sessionData.uid;
    yield put(FirebaseActions.setUserIdAction(userId));
    sStorage.setUserId(userId);
    yield put(validateAccessTokenSuccessAction(token));
  } catch (error) {
    yield put(validateAccessTokenFailAction(error));
  }
}

export default function* watchValidation() {
  yield all([takeEvery(VALIDATE_ACCESS_TOKEN_REQUEST, handleValidateAccessTokenRequest)]);
}
