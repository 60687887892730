// @flow
import type { SubscriptionsState } from '../state';
import {
  Action,
  RESTORE_SUBSCRIPTION_CONFIRM,
  RESTORE_SUBSCRIPTION_FAILED,
  RESTORE_SUBSCRIPTION_REQUEST,
  RESTORE_SUBSCRIPTION_SUCCESS,
  SuccessResponseAction,
} from '../actions';

import { GET_SUBSCRIPTIONS_SUCCESS, GET_SUBSCRIPTIONS_FAILED, GET_SUBSCRIPTIONS_REQUEST } from '../actions';
import { ESubscriptionRestoreState, ISubscription } from '../../types/subscription';

export const initState: SubscriptionsState = {
  loaded: false,
  subscriptions: [],
  restore: ESubscriptionRestoreState.None,
};

export default function subscriptionReducer(
  state: SubscriptionsState = initState,
  action: Action | SuccessResponseAction
) {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_REQUEST:
      return Object.assign({}, state, {
        loaded: false,
        subscriptions: [],
      });
    case GET_SUBSCRIPTIONS_SUCCESS:
      if (action.payload.response.status === 200) {
        const subscriptions: ISubscription[] = action.payload.response.data.subscriptions;
        // Active subscriptions should go first
        subscriptions.sort((subA, subB) => (subB.autoRenewing ? 1 : 0) - (subA.autoRenewing ? 1 : 0));
        return Object.assign({}, state, {
          loaded: true,
          subscriptions,
        });
      } else {
        return Object.assign({}, state, {
          loaded: true,
        });
      }
    case GET_SUBSCRIPTIONS_FAILED:
      return Object.assign({}, state, {
        loaded: true,
      });
    case RESTORE_SUBSCRIPTION_REQUEST: {
      return Object.assign({}, state, {
        restore: ESubscriptionRestoreState.Running,
      });
    }
    case RESTORE_SUBSCRIPTION_SUCCESS: {
      return Object.assign({}, state, {
        restore: ESubscriptionRestoreState.Success,
      });
    }
    case RESTORE_SUBSCRIPTION_CONFIRM:
    case RESTORE_SUBSCRIPTION_FAILED: {
      return Object.assign({}, state, {
        restore: ESubscriptionRestoreState.None,
      });
    }
    default:
      return state;
  }
}
