import * as ActionTypes from './actionTypes';
import { OrdersRequestError, OrdersRequestResponse } from './types';

export const initOrdersAction: (token: string) => ActionTypes.IInitOrdersAction = (token) => ({
  type: ActionTypes.ORDERS_INIT,
  token,
});

export const getOrdersRequestAction: () => ActionTypes.IGetOrdersRequestAction = () => ({
  type: ActionTypes.GET_ORDERS_REQUEST,
});

export const getOrdersSuccessAction: (response: OrdersRequestResponse) => ActionTypes.IGetOrdersSuccessAction = (
  response
) => ({
  type: ActionTypes.GET_ORDERS_SUCCESS,
  payload: {
    response,
  },
});

export const getOrdersFailedAction: (error: OrdersRequestError) => ActionTypes.IGetOrdersFailedAction = (error) => ({
  type: ActionTypes.GET_ORDERS_FAILED,
  payload: { error },
});
