import * as ActionTypes from './actionTypes';
import { EBannerMode, ICookieConsent } from './types';

export const initGdprAction: () => ActionTypes.IInitGdprAction = () => ({
  type: ActionTypes.GDPR_INIT,
});

export const updateConsentAction: (consent: ICookieConsent) => ActionTypes.IUpdateConsentAction = (consent) => ({
  type: ActionTypes.GDPR_UPDATE_CONSENT,
  consent,
});

export const acceptAllAction = () => {
  const consent: ICookieConsent = {
    firebase: true,
    optimize: true,
  };
  return saveConsentAction(consent);
};

export const saveConsentAction: (consent: ICookieConsent) => ActionTypes.ISaveUserConsentAction = (consent) => ({
  type: ActionTypes.GDPR_SAVE_USER_CONSENT,
  consent,
});

export const toggleSettingsAction: () => ActionTypes.IToggleSettingsAction = () => ({
  type: ActionTypes.GDPR_SETTINGS_TOGGLE,
});

export const updateBannerModeAction: (mode: EBannerMode) => ActionTypes.IUpdateBannerModeAction = (mode) => ({
  type: ActionTypes.BANNER_MODE_UPDATE,
  mode,
});
