import { Action } from 'redux';
import { EBannerMode, ICookieConsent } from './types';

export const GDPR_INIT = 'GDPR_INIT';
export type GDPR_INIT_TYPE = typeof GDPR_INIT;
export interface IInitGdprAction extends Action<GDPR_INIT_TYPE> {
  type: GDPR_INIT_TYPE;
}

export const GDPR_UPDATE_CONSENT = 'GDPR_UPDATE_CONSENT';
export type GDPR_UPDATE_CONSENT_TYPE = typeof GDPR_UPDATE_CONSENT;
export interface IUpdateConsentAction extends Action<GDPR_UPDATE_CONSENT_TYPE> {
  type: GDPR_UPDATE_CONSENT_TYPE;
  consent: ICookieConsent;
}

export const GDPR_SAVE_USER_CONSENT = 'GDPR_SAVE_USER_CONSENT';
export type GDPR_SAVE_USER_CONSENT_TYPE = typeof GDPR_SAVE_USER_CONSENT;
export interface ISaveUserConsentAction extends Action<GDPR_SAVE_USER_CONSENT_TYPE> {
  type: GDPR_SAVE_USER_CONSENT_TYPE;
  consent: ICookieConsent;
}

export const GDPR_SETTINGS_TOGGLE = 'GDPR_SETTINGS_TOGGLE';
export type GDPR_SETTINGS_TOGGLE_TYPE = typeof GDPR_SETTINGS_TOGGLE;
export interface IToggleSettingsAction extends Action<GDPR_SETTINGS_TOGGLE_TYPE> {
  type: GDPR_SETTINGS_TOGGLE_TYPE;
}

export const BANNER_MODE_UPDATE = 'BANNER_MODE_UPDATE';
export type BANNER_MODE_UPDATE_TYPE = typeof BANNER_MODE_UPDATE;
export interface IUpdateBannerModeAction extends Action<BANNER_MODE_UPDATE_TYPE> {
  type: BANNER_MODE_UPDATE_TYPE;
  mode: EBannerMode;
}

export type IGdprActions =
  | IInitGdprAction
  | IUpdateConsentAction
  | ISaveUserConsentAction
  | IToggleSettingsAction
  | IUpdateBannerModeAction;
