/* import type { CartState } from '../state';
import type { SuccessResponseAction } from '../actions';
import type { Cart, ECartItemType } from '../../types/cart';
import type { Coupon } from '../../types/coupon';
 */

import {
  ADD_PRODUCT_TO_CART_SUCCESS,
  DELETE_COUPONS_FROM_CART_SUCCESS,
  ADD_COUPON_TO_CART_SUCCESS,
  UPDATE_SUBSCRIPTION_DETAILS,
  ADD_PRODUCT_TO_CART_REQUEST,
  ADD_PRODUCT_TO_CART_FAIL,
  DELETE_COUPONS_FROM_CART_REQUEST,
  DELETE_COUPONS_FROM_CART_FAIL,
  ADD_PRODUCT_TO_CART_WRONG_COUPON,
  GET_SUBSCRIPTION_DETAILS_REQUEST,
  GET_SUBSCRIPTION_DETAILS_FAIL,
} from '../actions';
import { ESubscriptionDuration } from '../../types/product';

export const initState /* : CartState */ = {
  cart: null,
  type: null,
  subscription: {},
  coupon: null,
  loading: 0,
};

const getProductType /* : (Cart) => ?ECartItemType */ = (cart) => {
  if (!cart) {
    return null;
  }
  const products = cart.items;
  let type = null;
  for (const productId in products) {
    if (products[productId].type !== type) {
      type = products[productId].type;
    }
  }
  return type;
};

const findCoupon /* : (Cart) => ?Coupon */ = (cart) => {
  let coupon = null;
  if (cart.coupon) {
    return cart.coupon;
  }
  for (const productId in cart.items) {
    if (cart.items[productId].coupon) {
      coupon = cart.items[productId].coupon;
    }
  }
  return coupon;
};

export default function cartReducer(state /* : CartState */ = initState, action /* : SuccessResponseAction */) {
  const { type, payload } = action;
  switch (type) {
    case ADD_PRODUCT_TO_CART_REQUEST:
    case DELETE_COUPONS_FROM_CART_REQUEST:
    case GET_SUBSCRIPTION_DETAILS_REQUEST:
      return Object.assign({}, state, {
        loading: state.loading + 1,
      });
    case ADD_PRODUCT_TO_CART_FAIL:
    case ADD_PRODUCT_TO_CART_WRONG_COUPON:
    case DELETE_COUPONS_FROM_CART_FAIL:
    case GET_SUBSCRIPTION_DETAILS_FAIL:
      return Object.assign({}, state, {
        loading: state.loading - 1,
      });
    case ADD_PRODUCT_TO_CART_SUCCESS:
    case DELETE_COUPONS_FROM_CART_SUCCESS: {
      const cart = payload.response.data && payload.response.data.cart;
      return Object.assign({}, state, {
        cart,
        type: getProductType(cart),
        coupon: findCoupon(cart),
        loading: state.loading - 1,
      });
    }
    case ADD_COUPON_TO_CART_SUCCESS: {
      const cart = payload.response.data && payload.response.data.cart;
      return Object.assign({}, state, {
        cart,
        type: getProductType(cart),
        coupon: findCoupon(cart),
      });
    }
    case UPDATE_SUBSCRIPTION_DETAILS: {
      const subscriptionType = payload.subscription;
      const { periodType, periodValue } = subscriptionType;
      if (periodType === ESubscriptionDuration.Year && periodValue === 1) {
        // convert one year to 12 months
        subscriptionType.periodValue = 12;
        subscriptionType.periodType = ESubscriptionDuration.Month;
      }
      const subscription = Object.assign({}, state.subscription, {
        [payload.productId]: subscriptionType,
      });
      return Object.assign({}, state, {
        subscription,
        loading: state.loading - 1,
      });
    }
    default:
      return state;
  }
}
