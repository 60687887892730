import { IAnalyticsEventData, IEventParam } from './types';

export const prepareFirebaseEventParams = (parameters: IEventParam[] = []): IAnalyticsEventData => {
  const firebaseParams: IAnalyticsEventData = {};
  for (const param of parameters) {
    firebaseParams[param.key] = param.value;
  }
  return firebaseParams;
};

export const prefixGAEventName = (eventName: string) => {
  // add prefix gau_ to the event name according to BW-4884
  return eventName.startsWith('gau_') ? eventName : `gau_${eventName}`;
};
