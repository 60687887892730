// @flow

import type { SubscriptionType } from '../../types/product';

export const ADD_PRODUCT_TO_CART_REQUEST = 'ADD_PRODUCT_TO_CART_REQUEST';
export const ADD_PRODUCT_TO_CART_SUCCESS = 'ADD_PRODUCT_TO_CART_SUCCESS';
export const ADD_PRODUCT_TO_CART_FAIL = 'ADD_PRODUCT_TO_CART_FAIL';
// special action when coupon was wrong but cart might be loaded
export const ADD_PRODUCT_TO_CART_WRONG_COUPON = 'ADD_PRODUCT_TO_CART_WRONG_COUPON';

export const GET_SUBSCRIPTION_DETAILS_REQUEST = 'GET_SUBSCRIPTION_DETAILS_REQUEST';
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = 'GET_SUBSCRIPTION_DETAILS_SUCCESS';
export const GET_SUBSCRIPTION_DETAILS_FAIL = 'GET_SUBSCRIPTION_DETAILS_FAIL';
export const UPDATE_SUBSCRIPTION_DETAILS = 'UPDATE_SUBSCRIPTION_DETAILS';

export const DELETE_COUPONS_FROM_CART_REQUEST = 'DELETE_COUPONS_FROM_CART_REQUEST';
export const DELETE_COUPONS_FROM_CART_SUCCESS = 'DELETE_COUPONS_FROM_CART_SUCCESS';
export const DELETE_COUPONS_FROM_CART_FAIL = 'DELETE_COUPONS_FROM_CART_FAIL';

export const ADD_COUPON_TO_CART_REQUEST = 'ADD_COUPON_TO_CART_REQUEST';
export const ADD_COUPON_TO_CART_SUCCESS = 'ADD_COUPON_TO_CART_SUCCESS';
export const ADD_COUPON_TO_CART_FAIL = 'ADD_COUPON_TO_CART_FAIL';

export const addProductToCartRequestAction = (productId: string, coupon?: string) => {
  return {
    type: ADD_PRODUCT_TO_CART_REQUEST,
    payload: {
      productId,
      coupon,
    },
  };
};

export const addProductToCartSuccessAction = (response: any) => {
  return {
    type: ADD_PRODUCT_TO_CART_SUCCESS,
    payload: {
      response,
    },
  };
};

export const addProductToCartFailAction = (error: any) => {
  return {
    type: ADD_PRODUCT_TO_CART_FAIL,
    payload: {
      error,
    },
  };
};

export const addProductToCartWrongCouponAction = () => {
  return {
    type: ADD_PRODUCT_TO_CART_WRONG_COUPON,
  };
};

export const getSubscriptionDetailsRequestAction = (productId: string) => {
  return {
    type: GET_SUBSCRIPTION_DETAILS_REQUEST,
    payload: {
      productId,
    },
  };
};

export const getSubscriptionDetailsSuccessAction = (response: any) => {
  return {
    type: GET_SUBSCRIPTION_DETAILS_SUCCESS,
    payload: {
      response,
    },
  };
};

export const getSubscriptionDetailsFailAction = (error: any) => {
  return {
    type: GET_SUBSCRIPTION_DETAILS_FAIL,
    payload: {
      error,
    },
  };
};

export const updateSubscriptionDetailsAction = (productId: string, subscription: SubscriptionType) => {
  return {
    type: UPDATE_SUBSCRIPTION_DETAILS,
    payload: {
      productId,
      subscription,
    },
  };
};

export const deleteCouponsFromCartRequestAction = () => {
  return {
    type: DELETE_COUPONS_FROM_CART_REQUEST,
  };
};

export const deleteCouponsFromCartSuccessAction = (response: any) => {
  return {
    type: DELETE_COUPONS_FROM_CART_SUCCESS,
    payload: {
      response,
    },
  };
};

export const deleteCouponsFromCartFailAction = (error: any) => {
  return {
    type: DELETE_COUPONS_FROM_CART_FAIL,
    payload: {
      error,
    },
  };
};

export const addCouponToCartRequestAction = (coupon: string) => {
  return {
    type: ADD_COUPON_TO_CART_REQUEST,
    payload: {
      coupon,
    },
  };
};

export const addCouponToCartSuccessAction = (response: any) => {
  return {
    type: ADD_COUPON_TO_CART_SUCCESS,
    payload: {
      response,
    },
  };
};

export const addCouponToCartFailAction = (error: any) => {
  return {
    type: ADD_COUPON_TO_CART_FAIL,
    payload: {
      error,
    },
  };
};
