import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import getStore from './core';
import Root from './root';
import reportWebVitals from './reportWebVitals';

render(
  <Provider store={getStore()}>
    <Root />
  </Provider>,
  document.getElementById('checkout')
);

reportWebVitals();
