// @flow
import React from 'react';

type Props = {
  show?: boolean,
};

export default function Loading(props: Props) {
  const invisible = props.show === false;
  if (invisible) {
    return null;
  }
  const style = { width: '18rem' };
  return (
    <div className="loading card mx-auto border-0" style={style}>
      <img
        className="card-img-top"
        src="https://nncms.s3.amazonaws.com/assets/edison/onboarding/asset_loader.png"
        alt="Loading..."
      />
    </div>
  );
}
