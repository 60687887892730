import * as ActionTypes from './actionTypes';

export interface ILanguageState {
  language?: string;
}

export const initialState: ILanguageState = {
  language: undefined,
};

export const statePropName = 'language';

export default function featureReducer(
  state: ILanguageState = initialState,
  action: ActionTypes.TLanguageActions
): ILanguageState {
  switch (action.type) {
    case ActionTypes.LANGUAGE_CHANGE:
    case ActionTypes.LANGUAGE_INIT:
      return {
        ...initialState,
        language: undefined,
      };
    case ActionTypes.LANGUAGE_UPDATE:
      const { language } = action;
      return {
        ...state,
        language,
      };
    default:
      return state;
  }
}
