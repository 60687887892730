import { getHttpService } from 'web_opxp_components';
import Env from '../env';
import { UrlParams } from '../helpers/parameters';

export function createValidationService() {
  const http = getHttpService(Env.ApiUrl);
  const validateAccessToken = (accessToken: string) => {
    return http
      .request({
        url: '/validate',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .catch((reason) => {
        // when validation fails - remove saved access token
        localStorage.removeItem(UrlParams.AccessToken);
        return Promise.reject(reason);
      });
  };
  const service = {
    validateAccessToken,
  };
  return service;
}

const ValidationService = createValidationService();
export default ValidationService;
