export * from './types';

export * from './appActions';

export * from './validationActions';

export * from './checkoutActions';

export * from './cartActions';

export * from './paymentActions';

export * from './i18nextActions';

export * from './addressActions';

export * from './packagesActions';

export * from './subscriptionActions';

export * from './notificationActions';

// clear error from root alert box (input == root) or any other non validation error
export const ERROR_CLEAR = 'ERROR_CLEAR';

export const errorClearAction = (input: string) => {
  return {
    type: ERROR_CLEAR,
    payload: input,
  };
};
