// @flow
import type { Order } from '../../types/order';

export const CHECKOUT_START = 'CHECKOUT_START';
export const CHECKOUT_STARTED = 'CHECKOUT_STARTED';
export const CHECKOUT_FAIL = 'CHECKOUT_FAIL';
export const CHECKOUT_FINISHED = 'CHECKOUT_FINISHED';
export const COMPLETE_CHECKOUT = 'COMPLETE_CHECKOUT';
export const CHECKOUT_SUBMITTED = 'CHECKOUT_SUBMITTED';
export const COMPLETE_CHECKOUT_REQUEST = 'COMPLETE_CHECKOUT_REQUEST';
export const COMPLETE_CHECKOUT_SUCCESS = 'COMPLETE_CHECKOUT_SUCCESS';
export const COMPLETE_CHECKOUT_FAIL = 'COMPLETE_CHECKOUT_FAIL';
export const COMPLETE_CHECKOUT_VIEW = 'COMPLETE_CHECKOUT_VIEW';
export const ADD_TRAINING_ASSISTANT = 'ADD_TRAINING_ASSISTANT';
export const ADD_TRAINING_ASSISTANT_SUCCESS = 'ADD_TRAINING_ASSISTANT_SUCCESS';

export const checkoutStartAction = () => {
  return {
    type: CHECKOUT_START,
  };
};

export const checkoutStartedAction = () => {
  return {
    type: CHECKOUT_STARTED,
  };
};

export const checkoutFailAction = (error: string) => {
  return {
    type: CHECKOUT_FAIL,
    payload: {
      error,
    },
  };
};

export const checkoutFinishedAction = (order: Order) => {
  return {
    type: CHECKOUT_FINISHED,
    payload: {
      order,
    },
  };
};

export const completeCheckoutAction = (address: ?any, tokenOrSource: ?any) => {
  return {
    type: COMPLETE_CHECKOUT,
    payload: {
      address,
      tokenOrSource,
    },
  };
};

export const checkoutSubmitAction = () => {
  return {
    type: CHECKOUT_SUBMITTED,
  };
};

export const completeCheckoutRequestAction = (stripePaymentMethodId?: ?string, source?: ?string, method?: string) => {
  return {
    type: COMPLETE_CHECKOUT_REQUEST,
    payload: {
      stripePaymentMethodId,
      source,
      method,
    },
  };
};

export const completeCheckoutSuccessAction = (order: any) => {
  return {
    type: COMPLETE_CHECKOUT_SUCCESS,
    payload: {
      order,
    },
  };
};

export const completeCheckoutFailAction = (error: ?any) => {
  return {
    type: COMPLETE_CHECKOUT_FAIL,
    payload: {
      error,
    },
  };
};

export const setCompletedCheckoutViewAction = (completedView: string) => {
  return {
    type: COMPLETE_CHECKOUT_VIEW,
    payload: {
      completedView,
    },
  };
};

export const addTrainingAssistantAction = () => {
  return {
    type: ADD_TRAINING_ASSISTANT,
  };
};

export const addTrainingAssistantSuccess = () => {
  return {
    type: ADD_TRAINING_ASSISTANT_SUCCESS,
  };
};
