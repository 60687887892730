import { lazy, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import Loading from './components/base/Loading';
import { appStartAction } from './core/redux/actions/appActions';
import history from './core/historyService';

const CheckoutPage = lazy(() => import('./pages/CheckoutPage'));
const DiscountPage = lazy(() => import('./pages/DiscountPage'));
const FirstWarningPage = lazy(() => import('./pages/FirstWarning'));
const OrdersPage = lazy(() => import('./pages/OrdersPage'));
const ManagePage = lazy(() => import('./pages/ManagePage'));

export const mapDispatchToProps = {
  onStart: appStartAction,
};

export function Root({ onStart }) {
  useEffect(() => {
    onStart();
  }, [onStart]);
  return (
    <>
      <Router history={history}>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/">
              <CheckoutPage />
            </Route>
            <Route path="/discount/:subscriptionId">
              <DiscountPage />
            </Route>
            <Route path="/manage">
              <ManagePage />
            </Route>
            <Route path="/orders">
              <OrdersPage />
            </Route>
            <Route path="/terminate/:subscriptionId">
              <FirstWarningPage />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </>
  );
}

export default connect(null, mapDispatchToProps)(Root);
