import type { IState } from '../state';

/*
 * saga selectors: returns particular part of the state
 */

export const getPaymentMethod = (state: IState) => state.payment.paymentMethod;

export const paymentSelector = (state: IState) => state.payment;

export const cartSelector = (state: IState) => state.cart;

export const getCartType = (state: IState) => cartSelector(state).type;

export const getCart = (state: IState) => cartSelector(state).cart;

export const getCartTotal = (state: IState) => {
  const cart = getCart(state);
  if (!cart) {
    return null;
  }
  return cart.total;
};

export const appSelector = (state: IState) => state.app;

export const isSubmitted = (state: IState) => appSelector(state).submitted;

export const getProduct = (state: IState, productId: number) => getCart(state).items[productId];

export const getCartOriginal = (state: IState) => {
  const cart = getCart(state);
  if (!cart) {
    return null;
  }
  return cart.originalTotal;
};
