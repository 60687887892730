export function getUserId() {
  return sessionStorage.getItem('userId');
}

export function setUserId(userId /* : string */) {
  return sessionStorage.setItem('userId', userId);
}

export function getSessionToken() {
  return sessionStorage.getItem('sessionToken');
}

export function setSessionToken(sessionToken /* : string */) {
  return sessionStorage.setItem('sessionToken', sessionToken);
}

export function removeSessionToken() {
  return sessionStorage.removeItem('sessionToken');
}

export function getOrder() {
  const userId = getUserId();
  const orderStr = userId ? sessionStorage.getItem(`${userId}.order`) : null;
  return orderStr ? JSON.parse(orderStr) : orderStr;
}

export function setOrder(order) {
  const userId = getUserId();
  if (!userId) {
    throw new Error('Errors.Unauthorized');
  }
  return sessionStorage.setItem(`${userId}.order`, JSON.stringify(order));
}

export function removeOrder() {
  const userId = getUserId();
  if (!userId) {
    throw new Error('Errors.Unauthorized');
  }
  sessionStorage.removeItem(`${userId}.order`);
}

const storage = {
  getUserId,
  setUserId,
  getSessionToken,
  setSessionToken,
  removeSessionToken,
  getOrder,
  setOrder,
  removeOrder,
};

export default storage;
