import * as Reducer from './reducer';

export interface IAppState {
  // this feature state
  [Reducer.statePropName]: Reducer.IFirebaseState;
  // other features we don't care of
  [otherKeys: string]: any;
}

const getState = (state: IAppState) => state[Reducer.statePropName];

export const isReady = (state: IAppState) => getState(state).ready;

const getConfig = (state: IAppState) => getState(state).config;

export const getRestorableProductsConfig = (state: IAppState) => {
  const config = getConfig(state);
  const listString = config?.restoreProducts || '';
  return listString.split(',').map((value) => Number(value));
};
