// @flow

import type { Product } from '../../types/product';

export const GET_RELATED_PACKAGES_REQUEST = 'GET_RELATED_PACKAGES_REQUEST';
export const GET_RELATED_PACKAGES_SUCCESS = 'GET_RELATED_PACKAGES_SUCCESS';
export const GET_RELATED_PACKAGES_FAIL = 'GET_RELATED_PACKAGES_FAIL';

export const getRelatedPackagesRequestAction = (productId: string) => {
  return {
    type: GET_RELATED_PACKAGES_REQUEST,
    payload: {
      productId,
    },
  };
};

export const getRelatedPackagesSuccessAction = (productId: string, products: ?(Product[])) => {
  return {
    type: GET_RELATED_PACKAGES_SUCCESS,
    payload: {
      productId,
      products,
    },
  };
};

export const getRelatedPackagesFailAction = (error: any) => {
  return {
    type: GET_RELATED_PACKAGES_FAIL,
    payload: {
      error,
    },
  };
};
