import { Price } from './price';
import { Coupon } from './coupon';

export enum EProductType {
  ONE_TIME = 1,
  BUNDLE = 2,
  SUBSCRIPTION = 3,
  GROUP_ONE_TIME = 4,
  GROUP_SUBSCRIPTION = 5,
}

export enum ESubscriptionDuration {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export interface SubscriptionType {
  periodType: ESubscriptionDuration;
  periodValue: number;
  trialPeriodDays?: number;
}

export interface SubscriptionDetails {
  [id: string]: SubscriptionType;
}

export interface Product {
  id: number;
  name: string;
  type: EProductType;
  recommended: boolean;
  normalPrice: Price;
  discountedPriceWithoutApplyingCoupon: Price;
  discount: Price;
  discountPercentage: number;
  discountedPrice: Price;
  coupon?: Coupon;
  subscriptionDetails?: SubscriptionType;
  maxGroupUsers?: number;
}

export const TrainingAssistantInfo = {
  id: '29111',
  price: '199',
};
