import { InitOptions } from 'i18next';
import Env from '../env';

const localesUrl = Env.LocalesUrl;
const defaultLoadPath = `${localesUrl}/common/{{lng}}/{{ns}}.json`;

export function localeLoadPath(languages: string[], namespaces: string[]): string {
  // fallback to default if got unexpected arguments
  if (!languages.length || !namespaces.length) {
    return defaultLoadPath;
  }
  const namespace = namespaces[0];
  if (namespace.indexOf('.') < 0) {
    // fallback to default if namespace is usual
    return defaultLoadPath;
  }
  // parse namespace if it has a predefined format
  const parts = namespace.split('.');
  // get first part as 'workspace' - base folder of the repo path
  const workspace = parts.shift();
  // the rest should be just a tab/file name
  const namespacePath = parts.join('/');
  return `${localesUrl}/${workspace}/{{lng}}/${namespacePath}.json`;
}

export const defaultConfig: InitOptions = {
  fallbackLng: 'de',
  load: 'languageOnly',
  supportedLngs: ['de', 'en', 'fr', 'ru'],
  // debug: true,
  // FIXME: we load opxp.common namespace currently for all pages - that should be changed
  ns: ['opxp.common', 'common', 'checkout', 'products', 'countries'],
  defaultNS: 'checkout',
  fallbackNS: 'common',
  backend: {
    loadPath: localeLoadPath,
  },
  detection: {
    order: ['querystring', 'localStorage', 'navigator'],
    lookupLocalStorage: 'lang',
    lookupQuerystring: 'lang',
  },
};
