function removeLastSlash(str: string = '') {
  return str.replace(/\/$/, '');
}

const ApiUrl = removeLastSlash(process.env.REACT_APP_API_URL || '');
const ApiAppVersion = process.env.REACT_APP_API_VERSION || '3.0.0';
const PaymentApiUrl = process.env.REACT_APP_PAYMENT_URL || '';
const StripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
const LocalesUrl = process.env.REACT_APP_LOCALES_URL || '';

const AuthBaseUrl = removeLastSlash(process.env.REACT_APP_AUTH_URL);
export const PrivacyPolicyUrl = `${AuthBaseUrl}/legals/dataprivacy/?theme=opxp`;
export const LoginBaseUrl = `${AuthBaseUrl}/opxp/login/?theme=opxp`;
export const RegistrationBaseUrl = `${AuthBaseUrl}/opxp/signup/?theme=opxp`;

const RestoreSubscriptionRemoteConfig =
  process.env.REACT_APP_REMOTE_CONFIG_RESTORE_PRODUCTS || 'restore_subscription_products_dev';

export const AssetsBaseUrl = removeLastSlash(process.env.REACT_APP_ASSETS_URL);
export const BlogBaseUrl = removeLastSlash(process.env.REACT_APP_BLOG_URL);
export const TrainingBaseUrl = removeLastSlash(process.env.REACT_APP_OPXP_TRAINING_URL);
export const SubscribeBaseUrl = removeLastSlash(process.env.REACT_APP_OPXP_SUBSCRIBE_URL);
export const UserAppUrl = removeLastSlash(process.env.REACT_APP_OPXP_USER_URL);
export const SocialAppUrl = removeLastSlash(process.env.REACT_APP_OPXP_SOCIAL_URL);

const Env = {
  ApiUrl,
  ApiAppVersion,
  PaymentApiUrl,
  StripeKey,
  LocalesUrl,
  AuthBaseUrl,
  PrivacyPolicyUrl,
  RestoreSubscriptionRemoteConfig,
  AssetsBaseUrl,
  BlogBaseUrl,
  TrainingBaseUrl,
  SubscribeBaseUrl,
  UserAppUrl,
  SocialAppUrl,
  LoginBaseUrl,
  RegistrationBaseUrl,
};

export default Env;
