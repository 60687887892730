import i18next, { i18n, InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import { Dispatch } from 'redux';
import * as Actions from './actions';
import { defaultConfig } from './config';
import { ILanguageStore, TStoreListener } from './types';

function emptyT() {
  return 'Loading...';
}

export function initService() {
  let i18nextInstance: i18n;

  let store: ILanguageStore = {
    t: emptyT,
    i18n: i18next,
  };

  let listeners: TStoreListener[] = [];

  const subscribe = (listener: TStoreListener) => {
    if (!listeners.includes(listener)) {
      listeners.push(listener);
    }
    return () => unsubscribe(listener);
  };

  const unsubscribe = (oldListener: TStoreListener) => {
    listeners = listeners.filter((listener) => listener !== oldListener);
  };

  const update = () => {
    listeners.forEach((listener) => listener(store));
  };

  const init = async (dispatch?: Dispatch, customConfig?: InitOptions) => {
    if (dispatch) {
      // connect to redux if dispatch given
      listeners.push((store) => {
        dispatch(Actions.updateLanguageAction(store.language));
      });
    }
    // update listeners with initial state
    update();
    const config: InitOptions = {
      ...defaultConfig,
      ...customConfig,
    };
    i18nextInstance = i18next.createInstance();
    i18nextInstance.on('languageChanged', (lng) => {
      const isInitialized = i18nextInstance.isInitialized;
      const newStore: ILanguageStore = {
        language: isInitialized ? lng : undefined,
        t: isInitialized ? i18nextInstance.getFixedT(lng) : emptyT,
        i18n: i18nextInstance,
      };
      store = newStore;
      // update listeners with new state
      update();
    });
    i18nextInstance.on('initialized', () => {
      const language = store.language ? store.language : i18nextInstance.language;
      const newStore: ILanguageStore = {
        language,
        t: i18nextInstance.getFixedT(language),
        i18n: i18nextInstance,
      };
      store = newStore;
      // update listeners with new state
      update();
    });
    await i18nextInstance.use(XHR).use(LanguageDetector).init(config);
  };

  const changeLanguage = (lng: string) => {
    return i18nextInstance.changeLanguage(lng);
  };

  const getStore = () => {
    return store;
  };

  const getLanguage = () => {
    return store.language;
  };

  const getTFunction = () => {
    return store.t;
  };

  return {
    init,
    changeLanguage,
    subscribe,
    unsubscribe,
    getStore,
    getLanguage,
    getTFunction,
  };
}

const service = initService();
export default service;
