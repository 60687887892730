import { put, call, takeEvery, all, select } from 'redux-saga/effects';
// import i18next from 'i18next';

import type { StripeErrorAction } from '../actions';
import {
  GET_PAYMENT_SOURCES_REQUEST,
  CREATE_PAYMENT_REQUEST_METHOD,
  CREATE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_SOURCE_REQUEST,
  SET_DEFAULT_PAYMENT_SOURCE_REQUEST,
  COMPLETE_CHECKOUT_REQUEST,
  PAYMENT_STRIPE_ERROR,
  PAYPAL_SUCCESSFUL_AGREEMENT_METHOD,
  PAYPAL_CANCELED_AGREEMENT_METHOD,
  completeCheckoutSuccessAction,
  completeCheckoutFailAction,
  createPaymentSuccessAction,
  createPaymentFailAction,
  getPaymentSourcesRequestAction,
  getPaymentSourcesSuccessAction,
  getPaymentSourcesFailAction,
  setDefaultPaymentSourceSuccessAction,
  setDefaultPaymentSourceFailAction,
  deletePaymentSourceSuccessAction,
  deletePaymentSourceFailAction,
  validationErrorAction,
  savePaypalAgreementSuccessAction,
  savePaypalAgreementFailAction,
  paypalCanceledAgreementSuccessAction,
  paypalCanceledAgreementFailAction,
  setCompletedCheckoutViewAction,
  GET_PAYMENT_METHODS_LIST,
  updatePaymentMethodsListAction,
  CHECKOUT_FINISHED,
} from '../actions';

import type { StripeError, FormValidationError } from '../../types/error';
import { CheckoutCompletedViewEnum } from '../../types/base';
// import { TrainingAssistantInfo } from '../../types/product';

import {
  getPaymentMethod,
  appSelector,
  getCartTotal,
  getCartType,
  cartSelector,
  getProduct,
  getCartOriginal,
} from './selectors';

import paymentService from '../../../services/paymentService';
import Stripe from '../../../services/stripeService';

import sStorage from '../../../helpers/sessionStorage';
import { UrlParams } from '../../../helpers/parameters';
import { isOneTime, isSubscription } from '../../../helpers/checkCartItemType';
import { FirebaseActions } from '../../../firebase';

// converts stripe error to form validation error and dispatches an action accordingly
function* parseValidationError(stripeError: StripeError) {
  const error: FormValidationError = {
    input: stripeError.input,
    error: stripeError,
  };
  yield put(validationErrorAction(error));
}

// handles redirection to paypal
function* handlePaypalApprovalUrl(approvalUrl) {
  const appState = yield select(appSelector);

  if (appState.backUrl) {
    localStorage.setItem(UrlParams.BackUrl, appState.backUrl);
  }

  if (appState.completeUrl) {
    localStorage.setItem(UrlParams.CompleteUrl, appState.completeUrl);
  }

  localStorage.setItem(UrlParams.BaToken, approvalUrl.split('=')[1]);

  window.location.assign(approvalUrl);
}

// handles which completed checkout view should be shown
export function* handleCompletedCheckoutView() {
  let completedView;
  const EasePromotionIds = {
    3001: true,
    3002: true,
    3003: true,
  };
  // TODO: Training Assistant approach postponed
  // const language = i18next.language;
  const productId = localStorage.getItem(UrlParams.Product);
  // const trainingAssistantProductId = TrainingAssistantInfo.id;
  // const isTrainingAssistant = productId === trainingAssistantProductId;

  const appState = yield select(appSelector);
  const paymentMethod = yield select(getPaymentMethod);

  if (EasePromotionIds[productId]) {
    completedView = CheckoutCompletedViewEnum.EasePromotion;
  } else if (!appState.completeUrl) {
    completedView = CheckoutCompletedViewEnum.CrossPromotion;
  } /* else if (language === 'de' && !isTrainingAssistant) {
    completedView = CheckoutCompletedViewEnum.TrainingAssistant;
  } */ else if (
    paymentMethod === 'bank_transfer'
  ) {
    completedView = CheckoutCompletedViewEnum.BankTransfer;
  } else {
    completedView = CheckoutCompletedViewEnum.CompletedURL;
  }

  yield put(setCompletedCheckoutViewAction(completedView));
}

// handles errors generated by stripe: only one frontend error for now
export function* handleStripeError(action: StripeErrorAction) {
  const { type } = action.payload;
  switch (type) {
    case 'invalid_request_error': {
      yield parseValidationError(action.payload);
      break;
    }
    default:
      break;
  }
}

export function* handleCreatePaymentRequest(action) {
  const selectedPaymentMethod = yield select(getPaymentMethod);
  try {
    const response = yield call([paymentService, 'createPaymentSource'], action.payload.token, selectedPaymentMethod);
    yield put(createPaymentSuccessAction(response));
  } catch (error) {
    yield put(createPaymentFailAction(error));
  }
}

export function* handleGetPaymentSourcesRequest() {
  try {
    const result = yield call([paymentService, 'getPaymentSources']);
    yield put(getPaymentSourcesSuccessAction(result));
  } catch (error) {
    yield put(getPaymentSourcesFailAction(error));
  }
}

export function* handleCreatePaymentSuccess(action) {
  const selectedPaymentMethod = yield select(getPaymentMethod);

  if (selectedPaymentMethod === 'paypal') {
    const response = action.payload.response;
    const approvalUrl = response.data.approvalUrl;

    yield call(handlePaypalApprovalUrl, approvalUrl);
  } else {
    yield put(getPaymentSourcesRequestAction());
  }
}

export function* handleDeletePaymentRequest(action) {
  const sourceId = action.payload.sourceId;
  try {
    const response = yield call([paymentService, 'deletePaymentSource'], sourceId);
    yield put(deletePaymentSourceSuccessAction(response, sourceId));
  } catch (error) {
    yield put(deletePaymentSourceFailAction(error));
  }
}

export function* handleCompleteCheckoutRequest(action) {
  try {
    const { stripePaymentMethodId, source, method } = action.payload;
    const response = yield call([paymentService, 'completeCheckout'], stripePaymentMethodId, source, method);

    const data = response.data;
    let orderData = data ? data.order : null;

    // SCA feature
    if (data && data.requires_action) {
      const clientSecret = data.payment_intent_client_secret;
      const cartState = yield select(cartSelector);
      const stripeFunction = isSubscription(cartState.type) ? 'handleCardPayment' : 'handleCardAction';

      // open stripe confirmation modal
      const { error: errorAction, paymentIntent } = yield call([Stripe, stripeFunction], clientSecret);

      if (errorAction) {
        const errorMessage = errorAction.message;
        throw new Error(errorMessage);
      }
      const confirmResult = yield call([paymentService, 'confirmCheckout'], paymentIntent.id);
      orderData = confirmResult.data ? confirmResult.data.order : null;
    }

    if (orderData) {
      const order = Object.assign({}, orderData);
      order.method = method;
      order.productId = localStorage.getItem(UrlParams.Product);
      order.userId = sStorage.getUserId();
      sStorage.setOrder(order);

      yield put(completeCheckoutSuccessAction(order));
      yield call(handleCheckoutCompletedTracking);
      yield call(handleCompletedCheckoutView);
    }
  } catch (error) {
    yield put(completeCheckoutFailAction(error));
  }
}

export function* handleCheckoutCompletedTracking() {
  // get current productId
  const productId = localStorage.getItem(UrlParams.Product);
  // get name of the product
  const product = yield select(getProduct, productId);
  const packageName = product.name;
  // get total price and currency
  const total = yield select(getCartTotal);
  const currency = total.currency;
  const actualPrice = total.amount;
  // get original price
  const original = yield select(getCartOriginal);
  const origPrice = original.amount;
  // track completed checkout on firebase
  yield put(FirebaseActions.trackCompletedAction(productId, packageName, currency, origPrice, actualPrice));
  // track completed checkout on classic GA
  yield put(FirebaseActions.trackCompletedWithGAAction(productId, packageName, currency, origPrice, actualPrice));
  // track completed checkout on Taboola
  yield put(FirebaseActions.trackTaboolaPurchaseEventAction(actualPrice, currency));
  // track completed checkout on Outbrain
  yield put(FirebaseActions.trackOutbrainPurchaseEventAction(actualPrice, currency));
  // track completed checkout GA4
  yield put(FirebaseActions.trackCompletedWithGA4Action(currency, actualPrice));
}

export function* handleSetDefaultPaymentRequest(action) {
  const { sourceId, type } = action.payload;
  try {
    const response = yield call([paymentService, 'setDefaultPaymentSource'], sourceId, type);
    yield put(setDefaultPaymentSourceSuccessAction(response, sourceId));
  } catch (error) {
    yield put(setDefaultPaymentSourceFailAction(error));
  }
}

export function* handlePaypalSuccessfulAgreement(action) {
  const ba_token = action.payload.ba_token;
  const token = action.payload.token;
  try {
    const response = yield call([paymentService, 'paypalSuccessfulAgreement'], ba_token, token);
    yield put(savePaypalAgreementSuccessAction(response));
  } catch (error) {
    yield put(savePaypalAgreementFailAction(error));
  }
}

export function* handlePaypalCanceledAgreement(action) {
  const ba_token = action.payload.ba_token;
  try {
    const response = yield call([paymentService, 'paypalCanceledAgreement'], ba_token);
    yield put(paypalCanceledAgreementSuccessAction(response));
  } catch (error) {
    yield put(paypalCanceledAgreementFailAction(error));
  }
}

export function* handleAvailablePaymentMethodsListSaga() {
  // initial list of payment types
  const paymentTypes = [];
  // there is no conditions for card - just add it
  paymentTypes.push('card');
  // condition for SEPA
  const cartTotal = yield select(getCartTotal);
  if (!cartTotal) {
    throw Error('Cart is not loaded!');
  }
  const currency = cartTotal.currency;
  if (currency === 'EUR') {
    // add SEPA for EUR currency
    paymentTypes.push('sepa_debit');
  }
  // bank_transfer source type for one-time payments
  const cartType = yield select(getCartType);
  if (isOneTime(cartType)) {
    paymentTypes.push('bank_transfer');
  }
  // there is no condition for PayPal
  paymentTypes.push('paypal');
  yield put(updatePaymentMethodsListAction(paymentTypes));
}

export default function* watchPayment(): Generator<any, any, any> {
  yield all([
    takeEvery(GET_PAYMENT_SOURCES_REQUEST, handleGetPaymentSourcesRequest),
    takeEvery(CREATE_PAYMENT_REQUEST_METHOD, handleCreatePaymentRequest),
    takeEvery(CREATE_PAYMENT_METHOD_SUCCESS, handleCreatePaymentSuccess),
    takeEvery(DELETE_PAYMENT_SOURCE_REQUEST, handleDeletePaymentRequest),
    takeEvery(SET_DEFAULT_PAYMENT_SOURCE_REQUEST, handleSetDefaultPaymentRequest),
    takeEvery(COMPLETE_CHECKOUT_REQUEST, handleCompleteCheckoutRequest),
    takeEvery(PAYMENT_STRIPE_ERROR, handleStripeError),
    takeEvery(CHECKOUT_FINISHED, handleCompletedCheckoutView),
    takeEvery(PAYPAL_SUCCESSFUL_AGREEMENT_METHOD, handlePaypalSuccessfulAgreement),
    takeEvery(PAYPAL_CANCELED_AGREEMENT_METHOD, handlePaypalCanceledAgreement),
    takeEvery(GET_PAYMENT_METHODS_LIST, handleAvailablePaymentMethodsListSaga),
  ]);
}
