import { UrlParams } from './parameters';

interface IQueryParam {
  param: string;
  value: string;
}

export function extractQueryParams(search: string) {
  const params: IQueryParam[] = [];
  search &&
    search.split('&').forEach((item) => {
      const pair = item.split('=');
      if (pair.length > 1) {
        params.push({
          param: pair[0],
          value: decodeURIComponent(pair[1]),
        });
      }
    });
  return params;
}

export function buildQueryString(params: IQueryParam[]) {
  return params
    .map((item) => {
      return item.param + '=' + encodeURIComponent(item.value);
    })
    .join('&');
}

// extracts given parameter value from given url search params string
export function getUrlSearchValue(search: string, param: string): string {
  let result = '';
  let tmp: string[] = [];
  if (!search) {
    return result;
  }

  search.split('&').forEach((item) => {
    tmp = item.split('=');
    if (tmp[0] === param) {
      result = decodeURIComponent(tmp[1]);
    }
  });

  return result;
}

// removes given parameter from given url search params string
export function removeUrlSearchParam(search: string, param: string) {
  const params = extractQueryParams(search);
  const filteredParams = params.filter((item) => item.param !== param);
  return buildQueryString(filteredParams);
}

export function retrieveBackUrl(search: string) {
  return getUrlSearchValue(search, UrlParams.BackUrl);
}

export function retrieveCompleteUrl(search: string) {
  return getUrlSearchValue(search, UrlParams.CompleteUrl);
}

export function retrieveTaboolaClickId(search: string) {
  return getUrlSearchValue(search, UrlParams.TaboolaClickId);
}

export function retrieveOutbrainClickId(search: string) {
  return getUrlSearchValue(search, UrlParams.OutbrainId);
}

export function retrieveCurrentSearchParameter(param: string) {
  const search = getCurrentUrlSearch();
  return getUrlSearchValue(search, param);
}

export function replaceQuerystring(search: string) {
  if (window.history.replaceState) {
    window.history.replaceState(null, '', '?' + search);
  } else {
    if (window.location.search !== search) {
      const { protocol, host, pathname } = window.location;
      const url = protocol + '//' + host + pathname + '?' + search;
      window.location.replace(url);
    }
  }
}

// adds query parameter to given url string
export function addUrlSearchParam(url: string, param: string, value: string) {
  if (url.indexOf('?') < 0) {
    // basic case when no query was set before
    return url + '?' + param + '=' + value;
  }
  const [baseUrl, search] = url.split('?');
  // parse existing search string
  const params = extractQueryParams(search);
  // add given parameter
  params.push({
    param,
    value,
  });
  // convert array back to url
  const newSearch = buildQueryString(params);
  return baseUrl + '?' + newSearch;
}

export function getForwarding(search: string) {
  const value = getUrlSearchValue(search, UrlParams.Forwarding);
  return parseInt(value, 10) === 1;
}

export function getSessionForwarding(search: string) {
  const value = getUrlSearchValue(search, UrlParams.ForwardSession);
  return parseInt(value, 10) === 1;
}

// returns search/query part of the current browser url
export function getCurrentUrlSearch() {
  const search = window.location.search;
  if (!search) {
    return search;
  }
  // remove ? sign from start of the string
  return search.substr(1);
}

export function returnAndRemoveUrlSearchParam(param: string) {
  const search = getCurrentUrlSearch();
  const receivedParam = getUrlSearchValue(search, param);
  replaceQuerystring(removeUrlSearchParam(search, param));

  return receivedParam;
}

export function isPaypalResponse() {
  const method = returnAndRemoveUrlSearchParam(UrlParams.Method);
  return method === 'paypal';
}

export function isSuccessfulPaypalResponse() {
  const value = returnAndRemoveUrlSearchParam(UrlParams.Success);
  return parseInt(value, 10) === 1;
}

const UrlParser = {
  getUrlSearchValue,
  removeUrlSearchParam,
  retrieveBackUrl,
  retrieveCompleteUrl,
  retrieveTaboolaClickId,
  retrieveOutbrainClickId,
  retrieveCurrentSearchParameter,
  replaceQuerystring,
  addUrlSearchParam,
  getCurrentUrlSearch,
  getForwarding,
  getSessionForwarding,
  isPaypalResponse,
  isSuccessfulPaypalResponse,
  returnAndRemoveUrlSearchParam,
};

export default UrlParser;
