import { currentLanguage } from './localization';

const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

export function formatDate(date) {
  let dateObject;
  try {
    dateObject = new Date(date);
    if (!isValidDate(dateObject)) {
      throw new Error('invalid date');
    }
    const options = { dateStyle: 'long' };
    return new Intl.DateTimeFormat(currentLanguage(), options).format(dateObject);
  } catch (error) {
    // fallback
    if (isValidDate(dateObject)) return dateObject.toLocaleDateString();
    return date;
  }
}
