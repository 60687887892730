import { createBrowserHistory } from 'history';
import Env from '../env';
import { removeSessionToken } from '../helpers/sessionStorage';
import BlogUrls from './blogUrls.json';
import { DASHBOARD_PATH, EVALUATION_PATH, FRIENDS_PATH, MESSAGES_PATH, PERSONALIZATION_PATH } from './constants';

const history = createBrowserHistory();
export default history;

export function getZendeskUrl(language?: string, session?: string) {
  const locale = language === 'en' ? 'en-Us' : language || 'de';
  const tokenParam = session ? `?signin=${session}` : '';
  return `${Env.ApiUrl}/zendesk/${locale}${tokenParam}`;
}

enum EBlogUrls {
  Blog = 'blog',
  Studies = 'studies',
  Projects = 'projects',
  Effectiveness = 'effectiveness',
  default = 'blog',
}

export const getBlogUrl = (lang: string = 'de', path = EBlogUrls.default) => {
  let blogPath = '';
  switch (lang) {
    case 'en':
      blogPath = BlogUrls[path].en;
      break;
    case 'de':
      blogPath = BlogUrls[path].de;
      break;
    case 'fr':
      blogPath = BlogUrls[path].fr;
      break;
    case 'ru':
      blogPath = BlogUrls[path].ru;
      break;
  }
  const blogUrl = new URL(blogPath, Env.BlogBaseUrl);
  return blogUrl.toString();
};

export const getEffectivenessUrl = (lang?: string) => getBlogUrl(lang, EBlogUrls.Effectiveness);
export const getStudiesUrl = (lang?: string) => getBlogUrl(lang, EBlogUrls.Studies);
export const getProjectsUrl = (lang?: string) => getBlogUrl(lang, EBlogUrls.Projects);

export const getNormalizedUrl = (url: URL) => {
  let normalizedUrl = url.toString();
  if (normalizedUrl.indexOf('/?') < 0 && normalizedUrl.indexOf('?') >= 0) {
    normalizedUrl = normalizedUrl.replace('?', '/?');
  }
  return normalizedUrl;
};

export const getExternalUrl = (url: string, lang?: string, session?: string) => {
  // get current origin
  const base = window.location.origin;
  // base will be applied only if url is relative
  const resultUrl = new URL(url, base);

  if (lang) {
    resultUrl.searchParams.set('lang', lang);
  }
  if (session) {
    resultUrl.searchParams.set('session', session);
  }
  return getNormalizedUrl(resultUrl);
};

export const getInternalUrl = (pathname?: string, search?: string) => {
  return history.createHref({ pathname, search });
};

export const getDashboardUrl = (lang?: string, session?: string) => {
  return getExternalUrl(`${Env.TrainingBaseUrl}${DASHBOARD_PATH}`, lang, session);
};

export const getEvaluationUrl = (lang?: string, session?: string) => {
  return getExternalUrl(`${Env.TrainingBaseUrl}${EVALUATION_PATH}`, lang, session);
};

export const getPersonalizationUrl = (lang?: string, session?: string) => {
  return getExternalUrl(`${Env.TrainingBaseUrl}${PERSONALIZATION_PATH}`, lang, session);
};

export const getSubscribePageUrl = (lang?: string, session?: string) => {
  return getExternalUrl(Env.SubscribeBaseUrl, lang, session);
};

export const getUserPageUrl = (language?: string, session?: string) =>
  getExternalUrl(Env.UserAppUrl, language, session);

const getSocialUrl = (subpage: string = '', language?: string, session?: string) =>
  getExternalUrl(`${Env.SocialAppUrl}${subpage}`, language, session);

export const getFriendsUrl = (language?: string, session?: string) => getSocialUrl(FRIENDS_PATH, language, session);

export const getMessagesUrl = (language?: string, session?: string) => getSocialUrl(MESSAGES_PATH, language, session);

export const getBackUrl = (path?: string, search?: string) => {
  const backUrlPath = path || history.location.pathname;
  const internalHref = getInternalUrl(backUrlPath, search);
  const backUrl = new URL(internalHref, window.location.origin);

  return getNormalizedUrl(backUrl);
};

export const getSsoRedirectUrl = (
  language: string,
  signup = false,
  backUrlPath?: string,
  logout?: boolean,
  signin?: string
) => {
  let redirectUrl;

  if (!signup) {
    redirectUrl = new URL(Env.LoginBaseUrl);
  } else {
    redirectUrl = new URL(Env.RegistrationBaseUrl);
  }

  const backUrl = getBackUrl(backUrlPath);

  redirectUrl.searchParams.set('backUrl', backUrl);
  redirectUrl.searchParams.set('lang', language);

  if (logout) {
    // we do not want to redirect user back after logout
    // let user stay on login page after logout (BW-5461)
    redirectUrl.searchParams.set('logout', '2');
  } else if (signin) {
    redirectUrl.searchParams.set('signin', signin);
  }

  return getNormalizedUrl(redirectUrl);
};

export const redirectToSSO = (
  language: string,
  backUrlPath?: string,
  logout?: boolean,
  signin?: string,
  signup = false
) => {
  const redirectUrl = getSsoRedirectUrl(language, signup, backUrlPath, logout, signin);
  removeSessionToken();

  return window.location.assign(redirectUrl);
};

export const redirectLogout = (language: string, backUrlPath = '') =>
  redirectToSSO(language, backUrlPath, true, '', false);
